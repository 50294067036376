
.home-brands {
  width:100%;
  background-color: $color_secondary;
  padding-bottom: 30px;
  padding-top: 10px;

  ul {
    margin-left:20px;
    margin-right:20px;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    li {
      padding: 20px 0px 0px;
    }
  }
}

#all-categories {
  .swiper {
    padding-bottom: 50px;
  }
}
