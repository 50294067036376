/**/
/* page */
/**/
.page {
  overflow: hidden;
}

.header-container {
  padding-left: 5px;
  padding-right: 5px;


  .upper {
    margin-left: 10rem;
    margin-right: 10rem;

    @media (max-width: 1666px) {
      margin-left: 0;
      margin-right: 2rem;
    }

    @media (max-width: 776px) {
      margin-left: 0;
      margin-right: 0;
    }

    /* @media screen and (max-width: 1023px) { !* tablet *!
       margin-left: 2rem;
       margin-right: 2rem;
     }*/
  }
}

.page-header {
  margin-bottom: 2.5rem;
  //box-shadow: 0 1px 3px rgba(69, 69, 69, 0.1);
}

.page-header__top {
  //margin-bottom: 1.25rem;
  padding-top: 2px;
  background-color: #30424e;
  color: #FFF;
  height: 3rem;

  .header-wrap {
    display: flex;
    justify-content: space-around;

    .contact-phone {
      margin-top: .7rem
    }

  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 15px;
    font-size: 12px;
    text-transform: uppercase;

    li {
      margin: 0 20px 0 0;
      padding: 0 20px 0 0;
      border-right: 1px solid #607480;

      a {
        .fa {
          padding-right: 5px;
        }
      }

      &:after {
        border-right: 1px solid #607480;
      }
    }
  }
}

.page-footer {
  background-color: #F6F6F6;
}

@media screen and (max-width: 1023px) { /* tablet */
  .page-footer {
    padding-top: 3rem;
    text-align: center;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .page-header {
    margin-bottom: 1.25rem;
    //padding: 0.625rem 0;
  }
  .page-header__top {
    display: none;
  }
}


/*----------------------------------------------*/
/*                    HEADER                    */
/*----------------------------------------------*/

/**/
/* logo */
/**/
.logo {
  margin-top: 10px;
  padding-right: 2.5rem;
  @media (max-width: 1666px) {
    padding-right: .5rem;
    img {
      height: 5rem;
    }
  }
}


@media screen and (max-width: 767px) { /* mobile */
  .logo {
    padding-right: 0;
    margin-left: 6px;
    margin-top: 0;
  }
  .logo img {
    width: auto;
    height: 2.5rem;
  }
}


/**/
/* slogan */
/**/
.slogan {
  font-size: 0.75rem;
  line-height: 2rem;
  font-weight: 700;
}


/**/
/* quick nav */
/**/
.quick-nav {
  font-size: 0;
  text-align: right;
}

.quick-nav__link {
  display: inline-block;
  margin-left: 1.25rem;
  font-size: 0.75rem;
  line-height: 2rem;
  font-weight: 700;
}

.quick-nav__link_tel {
  font-size: 0.875rem;
}

.quick-nav__link:hover {
  text-decoration: underline;
}

.quick-nav__link:first-child {
  margin-left: 0;
}



/**/
/* main search */
/**/
.main-search {
  flex-grow: 1;
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  &.-has-focus {

  }

  &.stick {
    position: absolute;
    width: 100%;
  }

  @media (max-width: 768px) {
    &.-has-focus {
      position: absolute;
      width: 100%;

      .selectedVehicle {
        display: block;
      }
    }

    &.stick {
      .selectedVehicle {
        display: block;
      }
    }
  }
}

.main-search__form {
  position: relative;
  margin-right: -1px;

  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: inset 0 0 0 1px #E2E2E2;

  &.-has-vehicle {
    background-color: #fff;
    border-radius: 0.25rem;
    border: none;
    line-height: 1.5rem;
  }

  .selectedVehicle {
    display: block;
    background-color: $color_primary;
    padding: 0.4375rem 0.75rem;
    margin-left: 2px;
    border: none;
    border-radius: 5px;
    color: #fff;

    @media (max-width: 768px) {
      display: none;
    }

    .vehicle {
      display: block;
      background-color: $color_primary;

      .bi {
        opacity: .5;
        margin-left: 10px;
        cursor: pointer;
      }

      .vehicle-text {
        background-color: $color_primary;
        padding-left: 0.625rem;
      }
    }

    @media (max-width: 768px) {
      padding: 2px;

      .vehicle {
        .vehicle-text {
          font-size: .7rem;
          line-height: 2rem;
        }

        .bi {
          margin-left: 5px;
          margin-right: 5px;

        }
      }
    }

  }

  .input-group-text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
  }


  .main-search__input-text {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  .input-text__input {
    box-shadow: none;
    border: 1px solid #E2E2E2;

    &.-has-vehicle {
      border-left: none;
    }
  }

}


.main-search__input {
  font-size: 1.0rem;
}

.main-search__icon {
  display: none;
  position: absolute;
  top: 1px;
  right: 1px;
  border-radius: 0.25rem;
  background-color: #FFF;
  font-size: 1.25rem;
  line-height: 1.875rem;
  pointer-events: none;
}

.main-search__button {
  position: absolute;
  top: 0.375rem;
  right: 0.375rem;
  width: 2.5rem;
  font-size: .95rem;
  line-height: 1.75rem;
  height: 38px;
}

@media screen and (max-width: 767px) { /* mobile */
  .main-search {
    margin: 0 6px;
    padding: 0;

    &.-has-focus {

    }
  }
  .main-search__input {
    font-size: 0.75rem;
    cursor: default;
    opacity: 1;
    transition-duration: 0.25s;
    transition-property: opacity;
  }
  .main-search__input:focus {
    opacity: 1;
  }
  .main-search__icon {
    display: block;
  }
  .main-search__button {
    display: block;
    background-color: transparent;
    color: #444444;
    font-size: 16px;
    margin-top: -3px;
    margin-right: -7px;

    .fa-search {
      margin-top: 12px;
    }

  }

  .main-search__button:hover {
    color: #444444;
  }


}


.navbar2 {

  //width: 100%;
  //background-color: #f3f3f3;


  z-index: 39;

  .main-nav-flex {
    display: flex;
    justify-content: space-between;
  }

  .menu-cont {

    overflow: hidden;
    height: 55px;
    //border-top: 1px solid #E2E2E2;
    border-bottom: 4px solid $color_primary;

    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;

    .all-categories {
      margin-top: 20px;
    }

    .menu-item {
      height: 15px;
      width: auto;

      padding: 25px 15px;
      font-size: 14px;
      text-transform: uppercase;

      cursor: pointer;

      position: relative;

      &.dropdown-nav {
        text-align: center;
        padding-right: 10px;
        color: $color_secondary;
      }

      .dropdown-nav {
        text-align: center;
        padding-right: 10px;
        color: $color_secondary;
      }

      /* .dropdown-nav::after {
         @extend %chevron;
         @extend %chevron-bottom;
         top: 0.29em;
         left: 7px;
       }*/

      a {

        font-weight: 700;
        color: #333;


        &:hover {
          color: #fff;
          text-decoration: none;
        }

        //&:visited {
        //  color: #333;
        //}
      }

      a.blue {
        color: #234476;
      }


      img {
        display: block;
        clear: both;
        margin-left: auto;
        margin-right: auto;
        height: 40px;
      }

      &:hover {


        a {
          color: #c12026;
        }
      }


      .main-menu {

        display: flex;
        position: absolute;

        .main-menu .category-menu-item .link {
          padding: 15px 20px 15px 15px;
          color: #fff;
        }

        .category-menu {

          line-height: 2rem;

          a {
            padding: 15px 20px 15px 15px;
            color: #fff;
            text-transform: none;
            font-weight: normal;


            img {
              float: left;
              height: 20px;
            }

            &:hover {
              color: #1c1c1e
            }
          }

          .category-menu-item {
            &:hover {
              a {
                color: #c12026;
              }
            }
          }

        }

        .category-list {
          a {
            color: #30303e;
            text-transform: none;
            font-weight: normal;

            &:hover {
              color: #1c1c1e;
              font-weight: bold;
            }
          }

          .header {
            a {
              font-weight: bold;
            }

          }


          .menu-groups .menu-list .view-all {
            position: absolute;
            bottom: 10px;
            font-weight: 700;
          }

        }


      }


    }
  }

}

.cat-container {
  overflow: hidden;
  width: 75%;
  height: 24px;
}

.main-menu {
  width: auto;
  min-height: 563px;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 10000;
  display: flex;

}


.main-menu ul {
  list-style: none;
  padding: 0;
}

.main-menu ul li {
  padding: 3px 10px 3px 10px;
  color: #fff;

}

.main-menu .menu-list ul li {
  padding: 2px 5px 2px 3px;
}

.main-menu ul li a {
  text-decoration: none;
}

.main-menu .category-menu {
  min-width: 400px;
  background-color: #444444;
}

.main-menu .category-menu-item {
  margin: 0
}

.main-menu .category-menu-item:not(:last-child) {
  border-bottom: none
}


.main-menu .category-menu-item::after {
  border-color: #fff;
  right: 15px;
  //margin-top: 6px;

  @extend %chevron;
  @extend %chevron-right;
  top: 0.75em;
  right: -1px;


}

.main-menu .category-menu-item.is-active {
  background-color: #f3f3f3;
}

.main-menu .category-menu-item.is-active:after {
  border-color: #1c1c1e
}

.main-menu .category-menu-item.is-active a.link {
  color: #c12026;
}

.main-menu .category-menu-list {
  width: auto
}


@media screen and (min-width: 1408px) {
  .desktop-header .item {
    margin-right: 60px
  }
  .desktop-header .item.logo-wrapper {
    margin-right: 170px
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .main-menu .category-menu {
    width: 210px
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .main-menu .category-image {
    display: none
  }

}


@media screen and (max-width: 1024px) { /* mobile */
  .cat-container {
    width: 70%;
  }
}


.menu-groups {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 400px;
  //min-height:563px;
  display: none;
}

.menu-groups:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.menu-groups .link {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  color: #2a2b2a;
  text-decoration: none;
  position: relative
}

.menu-groups .link.has-children:after {
  border: 1px solid #2a2b2a;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 6px;
  width: 6px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  margin: auto;
  right: 1px;
  top: 0;
  bottom: 0
}

.menu-groups .link.bold-action:hover {
  font-weight: 900
}

.menu-groups .link.underline-action:hover {
  text-decoration: underline
}

.menu-groups .header {
  margin-bottom: 20px;
  font-weight: 700
}

.menu-groups .header .link {
  padding: 0
}

.menu-groups .flex-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.menu-groups .menu-list {
  padding: 20px;
  position: relative;
  background-color: #f3f3f3;
  z-index: 100
}

.menu-groups .menu-list .list {
  width: 200px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  font-size: 14px;
  text-transform: none
}

.menu-groups .menu-list .list + .list {
  margin-left: 20px;
  border-left: 2px solid #d5d5d5;
  padding-left: 20px
}

.menu-groups .menu-list .list-item:first-child .link {
  padding-top: 0
}


.menu-groups .sub-categories {
  background-color: #d5d5d5;
  height: 100%;
  width: 237px;
  z-index: 60
}

.menu-groups .sub-categories .list {
  width: 100%
}

.sub-menu-list {
  background-color: #d5d5d5;
  overflow: hidden;
  -webkit-transition: width .3s ease;
  transition: width .3s ease
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .menu-groups .menu-list {
    padding: 15px
  }
  .menu-groups .menu-list .list {
    width: 150px
  }
  .menu-groups .menu-list .list + .list {
    margin-left: 15px;
    padding-left: 15px
  }
  .menu-groups .menu-list .list-item .link {
    padding-right: 10px
  }
}

@media screen and (min-width: 1216px) {
  .menu-groups .sub-categories {
    width: 200px
  }
}

@media screen and (min-width: 1408px) {
  .menu-groups .sub-categories {
    width: 241px
  }
}


.menu-list {
  line-height: 1.25;
  // this is the minumum size of the light gray subcategory/part menu that appears when you hover over a category
  min-width: 400px;
}

.menu-list a {
  border-radius: 2px;
  color: #4a4a4a;
  display: block;
  padding: .5em .75em
}

.menu-list a:hover {
  background-color: #f5f5f5;
  color: #363636
}

.menu-list a.is-active {
  background-color: #3273dc;
  color: #fff
}

.menu-list li ul {
  border-left: 1px solid #dbdbdb;
  margin: .75em;
  padding-left: .75em
}



