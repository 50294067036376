/**/
/* fonts */
/**/
/*@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,700');
@import url('https://fonts.googleapis.com/css?family=Oswald:400,500');
@import url('https://fonts.googleapis.com/css?family=Material+Icons');*/

@import url('https://fonts.googleapis.com/css?family=Material+Icons');

/**/
/* defaults */
/**/
body {
  font-family: $font-family-primary, sans-serif;
  //font-family: PT Sans, Helvetica, Arial, sans-serif;

}

body.noscroll {

}

%chevron {
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  content: '';
  display: inline-block;
  height: 0.65em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.65em;
}

%chevron-right {
  left: 0;
  transform: rotate(45deg);
}

%chevron-bottom {
  left: 0;
  transform: rotate(135deg);
}

%chevron-left {
  left: 0;
  transform: rotate(-135deg);
}


label {
  margin: 0;
}

button {
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  color: inherit;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

dl, dt, dd, ul, ol, li {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

a, a:hover {
  text-decoration: none;
  color: inherit;
}

input:focus,
button:focus,
textarea:focus {
  outline: none;
}


/**/
/* grid */
/**/
.container {
  max-width: 1164px;
  margin: 0 auto;
}

.trust-container {
  max-width: none !important;
}

.container_sm {
  max-width: 864px;
}

.grid-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}

.grid-row-col {
  display: flex;
  flex-direction: column;
  align-content: stretch;
}

.grid-col {
  padding: 0 6px;
}

.grid-col_1 {
  flex-basis: 4.166%;
  max-width: 4.166%;
}

.grid-col_2 {
  flex-basis: 8.333%;
  max-width: 8.333%;
}

.grid-col_3 {
  flex-basis: 12.5%;
  max-width: 12.5%;
}

.grid-col_4 {
  flex-basis: 16.666%;
  max-width: 16.666%;
}

.grid-col_5 {
  flex-basis: 20.833%;
  max-width: 20.833%;
}

.grid-col_6 {
  flex-basis: 25%;
  max-width: 25%;
}

.grid-col_8 {
  flex-basis: 33.333%;
  max-width: 33.333%;
}

.grid-col_9 {
  flex-basis: 37.5%;
  max-width: 37.5%;
}

.grid-col_10 {
  flex-basis: 41.666%;
  max-width: 41.666%;
}

.grid-col_11 {
  flex-basis: 45.833%;
  max-width: 45.833%;
}

.grid-col_12 {
  flex-basis: 50%;
  max-width: 50%;
}

.grid-col_13 {
  flex-basis: 54.166%;
  max-width: 54.166%;
}

.grid-col_14 {
  flex-basis: 58.333%;
  max-width: 58.333%;
}

.grid-col_16 {
  flex-basis: 66.666%;
  max-width: 66.666%;
}

.grid-col_20 {
  flex-basis: 83.333%;
  max-width: 83.333%;
}

.grid-col_24 {
  flex-basis: 100%;
  max-width: 100%;
}

@media screen and (max-width: 1211px) { /* laptop */

  body.noscroll {
    position: fixed;
    overflow: hidden;
  }

  .container {
    max-width: 972px;
  }
}

@media screen and (max-width: 1023px) { /* tablet */
  .container {
    max-width: 732px;
  }
  .grid-col_tb-4 {
    flex-basis: 16.666%;
    max-width: 16.666%;
  }
  .grid-col_tb-5 {
    flex-basis: 20.833%;
    max-width: 20.833%;
  }
  .grid-col_tb-6 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .grid-col_tb-8 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  .grid-col_tb-10 {
    flex-basis: 41.666%;
    max-width: 41.666%;
  }
  .grid-col_tb-11 {
    flex-basis: 45.833%;
    max-width: 45.833%;
  }
  .grid-col_tb-12 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .grid-col_tb-13 {
    flex-basis: 54.166%;
    max-width: 54.166%;
  }
  .grid-col_tb-18 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .grid-col_tb-19 {
    flex-basis: 79.166%;
    max-width: 79.166%;
  }
  .grid-col_tb-20 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }
  .grid-col_tb-24 {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .container {
    //margin: 0 1rem;
  }
  .grid-col_ps-6 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .grid-col_ps-8 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  .grid-col_ps-11 {
    flex-basis: 45.833%;
    max-width: 45.833%;
  }
  .grid-col_ps-12 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .grid-col_ps-24 {
    flex-basis: 100%;
    max-width: 100%;
  }
}


/**/
/* helpers */
/**/
.fs0 {
  font-size: 0;
}

.tbl {
  display: table;
  width: 100%;
  height: 100%;
}

.tcl {
  display: table-cell;
  vertical-align: middle;
}

.removed {
  display: none;
}

.flexed {
  display: flex;
}

.fl-aic {
  align-items: center;
}

.fl-jcsb {
  justify-content: space-between;
}

.fl-nwrp {
  flex-wrap: nowrap;
}

@media screen and (max-width: 1211px) { /* laptop */
  .removed_lp {
    display: none;
  }
}

@media screen and (max-width: 1023px) { /* tablet */
  .removed_tb {
    display: none;
  }
  .blocked_tb {
    display: block;
  }
  .iblocked_tb {
    display: inline-block;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .removed_ps {
    display: none;
  }
  .blocked_ps {
    display: block;
  }
  .iblocked_ps {
    display: inline-block;
  }
}


/**/
/* badges */
/**/
.badge {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 1px 0.125rem 0 0.375rem;
  font-size: 0.6875rem;
  line-height: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #FFF;
}

.badge::after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  height: 1px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 4px solid transparent;
  box-sizing: content-box;
}

.badge_bs {
  background-color: #BD10E0;
}

.badge_bs::after {
  border-left-color: #BD10E0;
}

.badge_new {
  background-color: #FF1111;
}

.badge_new::after {
  border-left-color: #FF1111;
}


/**/
/* buttons */
/**/
.button {
  display: inline-block;
  vertical-align: top;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  line-height: 2.75rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: #FFF;
  transition-duration: 0.25s;
  transition-property: background-color;
}

.button:hover {
  color: #FFF;
}

.button_sm {
  line-height: 2.25rem;
}

.button_lg {
  font-size: 1rem;
  line-height: 3.75rem;
}

.button_red {
  background-color: $color_primary;
}

.button_gray {
  background-color: #444444;
}

.button_blue {
  background-color: #234476;
}

.button_blue:hover {
  background-color: #2F5A9C;
}

.button_green {
  background-color: #31B816;
}

.button_green:hover {
  background-color: #38D119;
}

.button_blocked {
  width: 100%;
}

@media screen and (max-width: 767px) { /* mobile */
  .button_lg {
    font-size: 0.875rem;
    line-height: 3.5rem;
  }
}


/**/
/* controls */
/**/
.input-text {
  position: relative;

  .cart-widget & {
    @media screen and (max-width: 767px) {
      float: left;
    }
  }
}

.input-text__input,
.input-text__textarea {
  width: 100%;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #FFF;
  box-shadow: inset 0 0 0 1px #E2E2E2;
  font-size: 0.875rem;
  line-height: 1.5rem;
  resize: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  height: 50px;
}

.input-text__textarea {
  height: 100px;
}

.input-text__input:focus::-moz-placeholder,
.input-text__textarea:focus::-moz-placeholder {
  color: transparent;
}

.input-text__input:focus::-webkit-input-placeholder,
.input-text__textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.input-select {
  position: relative;
}

.input-select__icon {
  position: absolute;
  top: 0;
  right: 0.5rem;
  font-size: 0.875rem;
  line-height: 2.5rem;
  pointer-events: none;
  color: #454545;
}

.input-select__select {
  width: 100%;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #FFF;
  box-shadow: inset 0 0 0 1px #E2E2E2;
  font-size: 0.875rem;
  line-height: 1.5rem;
  -moz-appearance: none;
  -webkit-appearance: none;
}

@media screen and (max-width: 767px) { /* mobile */
  .input-text__input {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .input-select__icon {
    font-size: 0.75rem;
    line-height: 2rem;
  }
  .input-select__select {
    padding-right: 1.75rem;
    padding-left: 0.75rem;
    font-size: 0.75rem;
    line-height: 1rem;
  }
}


/**/
/* typography */
/**/
h1, .h1 {
  margin: 0 0 0 -0.05em;
  font-size: 4rem;
  line-height: 1.25;
  font-weight: 500;
}

h2, .h2 {
  margin: 0 0 0 -0.05em;
  font-size: 2.5rem;
  line-height: 1.25;
  font-weight: 500;
}

h3, .h3 {
  margin: 0 0 0 -0.05em;
  font-size: 1.875rem;
  line-height: 1.25;
  font-weight: 700;
}

h4, .h4 {
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: 700;
}

h5, .h5 {
  margin: 0;
  font-size: 1.0rem;
  line-height: 1.25;
  font-weight: 500;
}

h6, .h6 {
  margin: 0;
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

p, .p {
  font-size: 0.875rem;
  font-weight: 400;
}

@media screen and (max-width: 1023px) { /* tablet */
  h2, .h2 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  h1, .h1 {
    font-size: 2.25rem;
  }
  h2, .h2 {
    font-size: 2rem;
  }
  h3, .h3 {
    font-size: 1.5rem;
  }
  h4, .h4 {
    font-size: 1.25rem;
  }
  h5, .h5 {
    font-size: 1rem;
  }
  h6, .h6 {
    font-size: 0.75rem;
  }
  p, .p {
    font-size: 0.75rem;
  }
}


/**/
/* account nav */
/**/
.account-nav {
  position: relative;
  padding-left: 1.25rem;
}

.account-nav__button {
  position: relative;
  display: block;
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 500;
  background-color: transparent;

  .icon-a {
    display: none;
  }

  @media (max-width: 1666px) {
    /*  .icon-a {
        display: block;
      }*/
    .text {
      display: none;
    }
  }

}

.account-nav__button i {
  vertical-align: top;
  margin-left: 0.25rem;
  font-size: 0.625rem;
  line-height: inherit;
}

.account-nav__button i:last-child {
  display: none;
}

.account-nav__button::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 1px;
  left: 0;
  border-bottom: 2px solid;
  opacity: 0;
  transition-duration: 0.25s;
  transition-property: opacity;
}

.account-nav__button:hover::before {
  opacity: 0.5;
}

.account-nav__button_dot::after {
  content: '';
  position: absolute;
  top: -0.125rem;
  right: -0.125rem;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: #FF1111;
}

.account-nav__dropdown {
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 0;
  margin-top: 0.75rem;
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  background-color: #FFF;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transition-duration: 0.25s;
  transition-property: opacity;
}

.account-nav__dropdown_active {
  pointer-events: auto;
  opacity: 1;
}

.account-nav__link {
  display: block;
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.account-nav__link:hover {
  text-decoration: underline;
}

.account-nav__link .logout {
  color: #848484;
}

.account-nav__link:first-child {
  margin-top: 0;
}

@media screen and (max-width: 767px) { /* mobile */
  .account-nav__button {
    padding: 0 6px;
    font-size: 0;
    line-height: 1.5rem;
  }
  .account-nav__button i {
    display: none;
    margin: 0;
    font-size: 1.125rem;
  }
  .account-nav__button i:last-child {
    display: inline-block;
    vertical-align: top;
  }
  .account-nav__button_dot::after {
    top: 0;
    right: 0;
  }

  .account-nav {
    padding-left: 0;
  }
}


/**/
/* cart button */
/**/
.cart-button {
  position: relative;

  .items-count {
    position: absolute;
    left: -5px;
    top: 10px;

    @media screen and (max-width: 767px) {
      left: 1px;
      top: 10px;
    }

    // circle
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background-color: #30424e;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: center;
    line-height: 1.2rem;
    z-index: 10;
  }
}

.cart-button svg {
  display: block;
  height: 1.425rem;
}

@media screen and (max-width: 767px) { /* mobile */
  .cart-button {
    padding: 0 6px;
  }
}


/**/
/* burger */
/**/
.burger {
  display: none;
  padding-left: 6px;
}

.burger i {
  vertical-align: top;
  padding-right: 5px;
}

@media screen and (max-width: 767px) { /* mobile */
  .burger {
    display: block;
  }
}


/**/
/* main nav */
/**/
.main-nav {
  display: flex;
  justify-content: space-between;
  //margin-top: 0.25rem;
  //padding: 0.25rem 0;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.main-nav__link {
  transition-duration: 0.25s;
  transition-property: color;
}

.main-nav__link_all {
  //margin-left: 1em;
  color: #234476;
}

.main-nav__link_all:visited {
  color: #234476;
}

.main-nav__link:hover {
  text-decoration: underline;

}

@media screen and (max-width: 1211px) { /* laptop */
  .main-nav {
    font-size: 0.8125rem;
  }
}

@media screen and (max-width: 1023px) { /* tablet */
  .main-nav {
    font-size: 0.75rem;
  }
  .main-nav__link_all {
    margin-left: 0.5em;
  }
  .main-nav__link:nth-child(8),
  .main-nav__link:nth-child(9) {
    display: none;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .main-nav {
    display: none;
  }
}


/**/
/* mobile nav */
/**/
.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 16rem;
  overflow: auto;
  background-color: #FFF;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.2), 0 0 0 32rem rgba(0, 0, 0, 0.5);
  pointer-events: none;
  opacity: 0;
  transform: translateX(0%);
  transition-duration: 0.375s;
  z-index: 100001;
}

.mobile-nav_active {
  pointer-events: auto;
  opacity: 1;
  transform: none;
}

.mobile-nav::after {
  content: '';
  display: block;
  height: 1rem;
}

.mobile-nav__close {
  display: block;
  margin-left: auto;
}

.mobile-nav__close i {
  vertical-align: top;
  width: 3.5rem;
  line-height: 3.25rem;
}

.mobile-nav__hr {
  margin: 1rem 1.25rem;
  border: none;
  border-top: 1px solid #F4EFED;
}

.mobile-nav__link {
  display: block;
  padding: 0 1.25rem;
  font-size: 0.875rem;
  line-height: 2.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

.mobile-nav__link_sec {
  font-size: 0.625rem;
  line-height: 2.25rem;
  color: #9D9A9A;
}

.mobile-nav__link_all {
  color: #234476;
}

.mobile-nav__link_active {
  background-color: rgba(35, 68, 118, 0.15);
}


/*----------------------------------------------*/
/*                    CONTENT                   */
/*----------------------------------------------*/

/**/
/* hero */
/**/
.hero {
  position: relative;
  height: 28rem;
  margin: -2.5rem 0 2.5rem;
  background: url('../images/hero/hero-home.png') 50% 50% no-repeat;
  background-size: cover;
  color: #FFF;
  margin-bottom: 0;
  text-align: center;
}

.hero-home {
  /*  font-size: 3.5rem;
    font-weight: 300;*/
  text-align: center;
  font-weight: 800;
  font-size: 36px;
  color: #2f424e;
  padding-top: 15px;
  text-transform: uppercase;
}

.hero_small {
  height: 13rem;
}

.section-contact-us {
  .hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  .hero__cont {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -582px;
    transform: translateY(-50%);
  }

  .hero__cont_center {
    right: 0;
    left: 0;
    margin: 0;
    text-align: center;
  }

  h4 {
    font-size: 2rem;

    @media screen and (max-width: 767px) { /* mobile */
      font-size: 1.3rem;
    }
  }
}


.trustbar_wrapper {
  width: 100%;
  background-color: $color_secondary;
  height: 72px;
  background: url("https://cdn.frooition.com/220163/files/trustbar_wrapper.jpg");
  margin-top: 3px;

  .trustbar {
    height: 72px;
    background: url("../images/hero/trustbar.jpg");
    width: 1000px;
    margin: auto;

  }
}

.hero__cont {
  /*  position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -582px;
    transform: translateY(-50%);*/

  .shadow {
    height: 20px;
    background: url("../images/hero/shadow.png");
    background-blend-mode: multiply;
    box-shadow: none;

    div {
      width: 100%;
      margin: auto;
    }
  }

}

.hero__cont_center {
  right: 0;
  left: 0;
  margin: 0;
  text-align: center;
}

.hero__title span {
  display: block;
}

.hero__suptitle {
  font-weight: 300;
}

.hero__button {
  display: inline-block;
  margin-top: 2rem;
  padding: 0 4.25rem;
  border-radius: 0.25rem;
  background-color: $color_primary;
  font-size: 1.25rem;
  line-height: 2.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  transition-duration: 0.25s;
  transition-property: background-color;
}

.hero__button:hover {
  background-color: #c12026;;
}

/*@media screen and (max-width: 1211px) { !* laptop *!
  .hero__cont {
    margin-left: -486px;
  }
  .hero__cont_center {
    margin-left: 0;
  }
}*/

@media screen and (max-width: 1023px) { /* tablet */
  .hero__cont {
    right: 0;
    left: 0;
    margin-left: 0;
    text-align: center;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .hero {
    height: 12rem;
    margin: -1.25rem 0 1.25rem;
    margin-bottom: 1px;
  }
  .hero_small {
    height: 8rem;
  }
  .hero__title span {
    font-size: 1.5rem;
    font-weight: 300;
  }
  .hero__button {
    margin-top: 1rem;
    padding: 0 3.5rem;
    font-size: 0.875rem;
  }
  .hero-home {
    font-size: 2.5rem;
    font-weight: 300;
  }
}


/**/
/* headline */
/**/
.headline {
  margin-bottom: 1.25rem;
}

.headline__hr {
  margin: 1.875rem 0;
  border: none;
  border-top: 1px solid #BFC1C6;
}

.headline__title sub {
  bottom: 0;
  font-size: 1.125rem;
}

.headline__subtitle {
  margin-top: 0.25rem;
}

.bread-crumbs + .headline {
  margin-top: -0.5rem;
}

@media screen and (max-width: 767px) { /* mobile */
  .headline__hr {
    margin: 1.25rem 0;
  }
  .headline__title sub {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .bread-crumbs + .headline {
    margin-top: 0;
  }
}


/**/
/* bread crumbs */
/**/
.bread-crumbs {
  margin-bottom: 0.75rem;
  font-size: 0.85rem;
}

.bread-crumbs i {
  vertical-align: top;
  font-size: inherit;
  line-height: inherit;
}

.bread-crumbs a {
  color: #234476;
}

.bread-crumbs a:hover {
  text-decoration: underline;
}

.bread-crumbs_main {
  margin: -1.75rem 0 3.25rem;
  margin-left: .64rem;
}

@media screen and (max-width: 767px) { /* mobile */
  .bread-crumbs {
    display: none;
  }
}


/**/
/* vehicle search */
/**/

.dropdown-cont {
  width: 100%;

  z-index: 100000;

  .my-garage {
    margin-left: 18px;
    // mobile
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }

  .my-cart {
    margin-right: 18px;
    // mobile
    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
  }

  .stick-icon {
    cursor: pointer;
    position: relative;
    display: none;
    margin-top: -.8rem;
    // mobile
    @media screen and (max-width: 767px) {
      margin-top: -1rem;
    }

    img {
      width: 3rem;
      height: auto;
    }

    .items-count {
      position: absolute;
      left: -15px;
      top: 10px;

      // create white ring with number of cart items in the middle
      /*   &::before {
             content: attr(data-count);

         }*/
      border: 2px solid #fff;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      text-align: center;
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 1.3rem;

      /*  // create white circle with number of cart items in the middle
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          background-color: white;
          color: #234476;
          font-size: 0.8rem;
          font-weight: 500;
          text-align: center;
          line-height: 1.5rem;
          top: 0;
          right: 0;*/


    }
  }

}

.stick {
  position: fixed !important;
  top: 0px;
  padding-top: 20px;
  //margin: 20px 0;
  background-color: $color_secondary;
  //height: 90px;

  &.main-search-2 {
    top: -10px;
    width: 100%;
    position: relative;
    margin-left: 10px;
    background-color: #444;
    margin-bottom: 20px;
  }

  &.main-search {
    margin: 0;
  }

  &.vehicle-offset {
    top: 70px;
  }

  .stick-icon {
    display: block;
  }

}


.stick-offset {
  padding-top: 135px;
  margin-top: 135px;

  .header-container {
    .upper {
      margin-left: 0;
      margin-right: 0;
    }
  }
}


.vehicle-search {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  //margin-top: -2.5rem;
  margin-bottom: 3rem;
  padding: 1.5rem 0;
  //height: 1.5rem;
  background-color: $color_secondary;;
  color: #FFF;
  position: relative;

  .stick & {
    margin-top: -0.3rem;
    margin-bottom: 1.1rem;
    padding: 0.1rem;
    height: 1.5rem;
    align-items: flex-start;
    justify-content: space-between;

    // mobile
    @media screen and (max-width: 767px) {
      height: 1.5rem;
    }

    .vehicle-search__label {
      &.choose {
        flex-basis: 100%;
        margin-bottom: 0.5rem;
        text-align: center;
      }
    }

  }

  &.dropdown-active {
    .stick & {
      height: 4.5rem;
    }
  }

  .vehicle-dropdown-wrap {
    display: flex;
  }

  &.highlight {
    background-color: #c0ffc8;
  }

  .highlight-search {

    display: none;
    // create a yellow transparent overlay
    //background-color: rgba(255, 255, 0, 0.5);
    background-color: yellow;
    // position the overlay on top of the search bar
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

  }

  .product-detail &, .product-line & {
    width: 100%;

    .vehicle-search__label {
      display: none;
    }

    .submit-cont {
      display: none;
    }
  }

  .selected-vehicle-dropdown {
    @extend .font-secondary-2, .-variant-1;
    position: relative;
    color: $color-neutral;

    margin-left: 1.5rem;

    @media screen and (max-width: 767px) {
      display: none;
    }

    .stick & {
      margin-top: -7px;
    }

    .vehicle-dropdown {
      color: $color-neutral;
      border: none;
      height: auto;
      //width: 100%;
      width: 12rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border: none;
      border-left: 1px solid white;
      background-color: transparent;
      box-shadow: none;
      cursor: pointer;

      .stick & {
        border-left: 0;
      }

      option {
        color: $color-secondary_light;
      }
    }

    &:after {
      border: 1px solid #fff;
      border-top-color: #fff;
      border-top-style: solid;
      border-top-width: 1px;
      border-right-color: #fff;
      border-right-style: solid;
      border-right-width: 1px;
      border-bottom-color: #fff;
      border-left-color: #fff;
      border-right: 0;
      border-right-color: currentcolor;
      border-top: 0;
      border-top-color: currentcolor;
      content: " ";
      display: block;
      height: .5em;
      pointer-events: none;
      position: absolute;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transform-origin: center;
      transform-origin: center;
      width: .5em;
      margin-top: -.375em;
      right: .125em;
      top: 50%;
      z-index: 4;
    }

  }


}

.vehicle-search__select {
  position: relative;
  width: 10.5rem;
  margin-left: 1rem;
  z-index: 2;

  &.year-cont {
    position: relative;

    .choose-notification {
      display: none;

      position: absolute;
      left: 50px;
      top: 40px;
      width: 60px;
      height: 100px;
      background-color: white;


      .bi {
        font-size: 6rem;
        color: red;
        position: relative;
        left: -18px;
      }
    }
  }

}

.vehicle-search__select i {
  position: absolute;
  top: 0;
  right: 0.5rem;
  font-size: 0.875rem;
  line-height: 2.5rem;
  pointer-events: none;
  color: #454545;
}

.vehicle-search__select select {
  width: 100%;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #FFF;
  font-size: 0.875rem;
  line-height: 1.5rem;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.vehicle-search__button {
  margin-left: 1rem;
  padding: 0 2.125rem;
  border-radius: 0.25rem;
  background-color: #234476;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 2.5rem;
  transition-duration: 0.25s;
  transition-property: background-color;
}

.vehicle-search__button.disabled {
  background-color: #efefef;
  color: black;
  cursor: auto;
}

.vehicle-search__button:hover {
  background-color: #2F5A9C;
}

.vehicle-search__button.disabled:hover {
  background-color: #efefef;
  cursor: auto;
}

@media screen and (max-width: 1023px) { /* tablet */
  .vehicle-search__label {
    font-size: 1rem;
  }
  .vehicle-search__select {
    width: 8.5rem;
    margin-left: 0.75rem;
  }
  .vehicle-search__button {
    margin-left: 0.75rem;
  }
}

@media screen and (max-width: 767px) { /* mobile */

  .dropdown-cont {
    width: 100%;

  }

  .stick {
    /*
    position: fixed;
    top: 0px;
    padding-top: 10px;
    margin: 10px 0;
     */
  }

  .stick {


    &.vehicle-offset {
      top: 50px;
    }

    .main-search-2 {
      margin-bottom: 5px;
      left: -11.5px;

      .input-text__input, .input-text__textarea {
        height: 30px;
      }

      .main-search__button {
        margin-top: -9px;
      }

      .vehicle-search__select {

      }

    }


  }

  .stick-offset {
    padding-top: 52px;
    margin-top: 52px;
  }


  .vehicle-search {
    margin: -1.25rem 0 1.25rem;
    //padding: 1rem 0 1.25rem;
    padding: 1rem 0 .4rem;

    .stick & {
      margin-top: -0.3rem;
      margin-bottom: 0.6rem;
      padding: 0.1rem;
    }

  }
  .no-vehicle-padding {
    padding: 1rem 0 1.25rem;
  }
  .vehicle-search__label {

    &.choose {
      flex-basis: 100%;
    }

    //flex-basis: 100%;
    margin-bottom: 0.5rem;
    text-align: center;
    font-size: 0.875rem;
  }
  .vehicle-search__select {
    width: 6.75rem;

    .stick & {
      width: 4.1rem;
      //margin-right: -12px;

      select {
        option {
          span {
            display: none;
          }
        }
      }
    }

    margin: 0 0.125rem;
  }
  .vehicle-search__select i {
    font-size: 0.75rem;
    line-height: 2rem;
  }
  .vehicle-search__select select {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    line-height: 1rem;

    .stick & {
      padding: 0.5rem .2rem;
    }
  }
  .vehicle-search__button {
    display: none;
  }
}

@media screen and (max-width: 359px) { /* mobile s */
  .vehicle-search__select {
    width: 6rem;
  }
  .vehicle-search__select select {
    padding: 0.5rem;
  }
}


/**/
/* search headline */
/**/
.search-headline {
  margin: -2.5rem 0 2.5rem;
  padding: 1.5rem 0;
  background-color: #F3F3F3;
}

.search-headline__form {
  position: relative;
}

.search-headline__title {
  margin-bottom: 1px;
  font-size: 1.125rem;
  color: #414141;
}

.search-headline__input {
  width: 100%;
  margin-left: -0.05em;
  padding: 0 10rem 0 0;
  border: none;
  background: none;
  box-shadow: none;
  font-size: 3rem;
  line-height: 1.25;
  font-weight: 300;
  pointer-events: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.search-headline__edit {
  background: none;
  font-size: 0.8125rem;
  text-decoration: underline;
  color: #234476;
  transition-duration: 0.25s;
  transition-property: opacity;
}

.search-headline__edit:hover {
  text-decoration: none;
}

.search-headline__button {
  position: absolute;
  top: 50%;
  right: 0;
  width: 9rem;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-50%);
  transition-duration: 0.25s;
  transition-property: opacity;
}

.search-headline_active .search-headline__edit {
  pointer-events: none;
  opacity: 0;
}

.search-headline_active .search-headline__input {
  pointer-events: auto;
}

.search-headline_active .search-headline__button {
  pointer-events: auto;
  opacity: 1;
}

@media screen and (max-width: 767px) { /* mobile */
  .search-headline {
    margin: -1.25rem 0 1.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .search-headline__title {
    font-size: 1rem;
  }
  .search-headline__input {
    padding-right: 6rem;
    font-size: 2rem;
  }
  .search-headline__button {
    width: 5rem;
  }
}


/**/
/* search again */
/**/
.search-again {
  margin-bottom: 5rem;
}

.search-again__title {
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.search-again__subtitle {
  letter-spacing: 0;
}

.search-again__list {
  margin-top: 1rem;
}

.search-again__list li {
  position: relative;
  margin-top: 0.5em;
  padding-left: 1rem;
  font-size: 0.875rem;
  line-height: 1.75;
}

.search-again__list li::before {
  content: '';
  position: absolute;
  top: 0.875em;
  left: 0;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: #234476;
  transform: translateY(-50%);
}


/**/
/* part name search */
/**/
.part-name-search {
  position: relative;
  margin-bottom: 1.25rem;
}

.part-name-search__input {
  font-size: 0.75rem;
  font-weight: 500;
}

.part-name-search__button {
  position: absolute;
  top: 0.375rem;
  right: 0.375rem;
  padding: 0 1rem;
  border-radius: 0.125rem;
  font-size: 0.75rem;
  line-height: 2.3rem;
}

@media screen and (max-width: 767px) { /* mobile */
  .part-name-search {
    margin: 1rem 0;
  }
  .part-name-search__button {
    top: 0.425rem;
    right: 0.425rem;
  }
}


/**/
/* filter */
/**/
.filter {
  margin-bottom: 5.5rem;
  padding: 1rem 0.75rem;
  //border: 1px solid #ECEDF0;
  //border-radius: 0.25rem;
}

.filter__heading {
  margin: 1.25rem -0.75rem 0;
  padding: 1.25rem 0.75rem 0;
  //border-top: 1px solid #ECEDF0;
  letter-spacing: 0.01em;
  font-size: .99rem;
  color: #1a335a;
}

.filter__heading:first-child {
  margin: 0;
  padding: 0;
  border: none;
}

.filter__item {
  color: #122542;
  font-size: 0.85rem;
  margin-top: 0.5rem;
}

.filter__item i {
  vertical-align: top;
  margin-right: 0.375rem;
  font-size: 0.85rem;
  line-height: inherit;
  transition-duration: 0.25s;
  transition-property: transform;
}

.filter__item a:hover,
.filter__item label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.filter__item_more {
  color: #234476;
}

.filter__item_active label i {
  color: #234476;
}

.filter__item_active label i::before {
  content: "\f14a";
}

.filter__item_more_active i {
  transform: scale(1, -1);
}

.filter__spoiler {
  display: none;
}

@media screen and (max-width: 767px) { /* mobile */
  .filter {
    display: none;
  }
}


/**/
/* sorting */
/**/
.sorting {
  display: flex;
  margin-bottom: 1.25rem;
  padding: 0.75rem 0;
  border-top: 1px solid #BFC1C6;
  border-bottom: 1px solid #BFC1C6;
  font-size: 0.75rem;
  font-weight: 500;
}

.sorting__label {
  margin-right: 0.375rem;
  padding-right: 0.25rem;
}

.sorting__link {
  margin: 0 0.375rem;
  padding: 0 0.25rem;
  color: #717275;
  transition-duration: 0.25s;
  transition-property: color;
}

.sorting__link:hover {
  color: #234476;
}

.sorting__link_hide {
  margin-right: 0;
  margin-left: auto;
  padding-right: 0;
}

.sorting__link_hide i {
  vertical-align: top;
  margin-right: 0.25rem;
  font-size: inherit;
  line-height: inherit;
}

.sorting__link_active {
  position: relative;
  color: #234476;
}

.sorting__link_active::after {
  content: '';
  position: absolute;
  top: 100%;
  right: -0.25rem;
  left: -0.25rem;
  margin-top: 0.75rem;
  border-top: 2px solid;
}

.sorting__select,
.sorting__filter {
  display: none;
  font-size: 1rem;
}

@media screen and (max-width: 767px) { /* mobile */
  .sorting {
    align-items: center;
    justify-content: space-between;
    padding: 0;
    border: none;
  }
  .sorting__label,
  .sorting__link {
    display: none;
  }
  .sorting__link_hide {
    display: block;
  }
  .sorting__select {
    display: block;
    width: 10rem;
    width: calc(50% - 6px);
  }
  .sorting__filter {
    display: block;
    font-weight: 400;
    text-decoration: underline;
    color: #0061A3;
  }
  .sorting__filter:hover {
    text-decoration: none;
    color: #0061A3;
  }
}


/**/
/* submodels */
/**/
.submodels {
  margin: -0.75rem 0 3.75rem;
}

/*
.submodels__item {
  display: block;
  overflow: hidden;
  margin-top: 0.75rem;
  padding: 0 0.75rem;
  border-radius: 0.25rem;
  background-color: #BDC6CD;
  line-height: 2.5rem;
  text-align: center;
  //text-overflow: overflow: hidden;;
  white-space: nowrap;
  color: #FFF;
  transition-duration: 0.25s;
  transition-property: background-color;
  cursor: pointer;
}
*/

.submodels__item {
  display: block;
  overflow: hidden;
  margin-top: 0.75rem;
  padding: 0 0.75rem;
  border-radius: 0.25rem;
  line-height: 1.9rem;
  text-align: center;
  white-space: nowrap;

  transition-duration: 0.25s;
  transition-property: background-color;
  cursor: pointer;
  color: black;
  font-size: 20px;
  border: 1px solid #c8c8c8;
}


.submodels__item:hover {
  background-color: $color_primary_dark;
  color: #FFF;
}

@media screen and (max-width: 767px) { /* mobile */
  .submodels {
    margin-bottom: 2.5rem;
  }
  .submodels__item {
    font-size: 0.875rem;
    line-height: 2.25rem;
  }
  .submodels__row {
    position: relative;
    display: block;
    margin: 0 -1rem;
  }
  .submodels__row::after {
    content: '';
    position: absolute;
    top: 0.75rem;
    right: 0;
    bottom: 0;
    width: 4rem;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
  }
  .submodels__row .slick-list {
    padding: 0 0.625rem;
  }
  .submodels__col {
    width: 9.5rem;
    max-width: none;
  }
}


/**/
/* category card */
/**/
.category-card {
  position: relative;

  margin-top: 0.75rem;
  padding: 1rem;
  border: 1px solid #E4E4E4;
  border-radius: 0.25rem;
  transition-duration: 0.25s;
  transition-property: box-shadow;


  display: flex;
  flex-direction: column;
  flex: 1;


}

.category-card__pic {
  position: relative;
  margin: -0.5rem 0;
  padding-top: 100%;
}

.category-card__pic img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  max-height: 220px;
}

.category-card__hr {
  margin: 1rem 0;
  border: none;
  border-top: 1px solid #E5E5E5;
}

.category-card__number {
  transition-duration: 0.25s;
  transition-property: color;
  margin-top: 5px;
}

.category-card:hover {
  box-shadow: 0.25rem 0.25rem 0 0 rgba(77, 77, 78, 0.2);
}

.category-card:hover .category-card__number {
  color: #31B816;
}

@media screen and (max-width: 767px) { /* mobile */
  .category-card {
    padding: 0.75rem;
  }
  .category-card:hover {
    box-shadow: none;
  }
}


/**/
/* category tips */
/**/
.category-tips {
  margin-bottom: 3.75rem;
}

.category-tips__video {
  position: relative;
  margin-top: 0.5rem;
  padding-top: 56.25%;
}

.category-tips__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media screen and (max-width: 767px) { /* mobile */
  .category-tips {
    margin-bottom: 2.5rem;
  }
}


/**/
/* category details */
/**/
.category-details {
  margin-bottom: 3.75rem;
  margin-right: 1.25rem;
  padding-right: 2rem;
  border-right: 1px solid #BFC1C6;
}

.category-details p {
  margin-top: 0.5em;
  line-height: 1.75;
}

.category-details h4 {
  margin-top: 1.33em;
}

.category-details h4:first-child {
  margin-top: 0;
}

.category-details li {
  position: relative;
  margin-top: 0.5em;
  padding-left: 1rem;
  line-height: 1.75;
}

.category-details li::before {
  content: '';
  position: absolute;
  top: 0.875em;
  left: 0;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: #234476;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) { /* mobile */
  .category-details {
    margin-right: 0;
    margin-bottom: 2.5rem;
    padding-right: 0;
    border-right: none;
  }
}


/**/
/* model card */
/**/
.model-card {
  display: block;
  margin-top: 0.75rem;
  text-align: center;
  transition-duration: 0.25s;
  transition-property: color;
}

.model-card:hover {
  color: #EB9810;
}

.model-card__image {
  max-width: 100%;
  border-radius: 0.125rem;
}

.model-card__name {
  margin-top: 0.5rem;
}

.model-card__goto {
  font-size: 0.75rem;
  color: #234476;
}

.model-card__goto i {
  vertical-align: top;
  margin-left: 0.25rem;
  font-size: inherit;
  line-height: inherit;
}

@media screen and (max-width: 1211px) { /* laptop */
  .model-card__image {
    height: auto;
  }
}


/**/
/* parts card */
/**/
.parts-card {
  display: flex;
  margin-top: 0.75rem;
  padding: 1rem 1.25rem;
  border: 1px solid #E4E4E4;
  border-radius: 0.25rem;
  text-align: left;
  transition-duration: 0.25s;
  transition-property: box-shadow;
  flex-direction: column;
  flex: 1;

}

.part-card {
  width: 10rem;
}

/*.shop-brands {
  .slick-initialized .slick-slide {
    height: 100%
  }
}*/

.parts-grow {
  flex-grow: 1;
}

.parts-card:hover {
  box-shadow: 0.25rem 0.25rem 0 0 rgba(77, 77, 78, 0.2);
}

.parts-card__pic {
  position: relative;
  height: 8rem;
}

.parts-card__pic img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  //height: auto;
  height: 7rem;
  margin: auto;

  #all-categories & {
    height: 10rem;
  }
}

.parts-card__number {
  margin-top: 0.25rem;
  font-size: 0.6875rem;
  font-weight: 700;
  color: #c12026;
}

.parts-card__heading {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.parts-card__heading_all {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.parts-card__subheading {
  margin-top: 0.25rem;
  line-height: 1.375;

  a:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .parts-card {
    padding: 0.75rem;
  }
  .parts-card__pic {
    height: 7rem;
  }
  .parts-card:hover {
    box-shadow: none;
  }
  .parts-card__heading {
    margin-top: 10px;
  }
}


/**/
/* main sectors */
/**/
.main-sectors {
  margin-bottom: 5.5rem;
}

.main-sectors__hr {
  margin: -2.5rem 0 3rem;
  border: none;
  border-top: 1px solid #BFC1C6;
}

.main-sectors__title {
  margin-bottom: 1.25rem;
  text-align: center;
}

.main-sectors__col {
  flex-basis: 20%;
  max-width: 20%;
  display: flex;
  flex-direction: column;
}

.main-sectors__counter {
  position: relative;
  display: none;
  vertical-align: top;
  margin-top: 0.5rem;
  padding-right: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.main-sectors__counter::after {
  content: '';
  position: absolute;
  bottom: 50%;
  left: 100%;
  width: 2rem;
  border-top: 1px solid;
}

@media screen and (max-width: 1211px) { /* laptop */
  .main-sectors__col {
    flex-basis: 25%;
    max-width: 25%;
  }
}

@media screen and (max-width: 1023px) { /* tablet */
  .main-sectors__col {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
}

@media screen and (max-width: 767px) { /* mobile */

  .main-sectors {
    margin-bottom: 3.5rem;
  }
  .main-sectors__hr {
    margin: -1.5rem 0 2rem;
  }
  .main-sectors__title {
    margin-bottom: 0.5rem;
  }
  .main-sectors__row {
    /*
    position: relative;
    display: block;
    height: 210px;
    overflow: hidden;
    margin: 0 -1rem;
    */

  }
  .main-sectors__row::after {
    /*
    content: '';
    position: absolute;
    top: 0.75rem;
    right: 0;
    bottom: 0;
    width: 4rem;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
    */

  }
  .main-sectors__row .slick-list {
    padding: 0 0.625rem;
  }
  .main-sectors__col {


    flex-basis: 49.999%;
    max-width: 49.9999%;
  }
  .main-sectors__counter {
    display: inline-block;
  }
}


/**/
/* popular parts */
/**/
.popular-parts {
  margin: 2.5rem 0 5.5rem;
}

.popular-parts__col {
  flex-basis: 20%;
  max-width: 20%;
}

@media screen and (max-width: 1211px) { /* laptop */
  .popular-parts__col {
    flex-basis: 25%;
    max-width: 25%;
  }
}

@media screen and (max-width: 1023px) { /* tablet */
  .popular-parts__col {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .popular-parts {
    margin-bottom: 3.5rem;
  }
  .popular-parts__col {
    flex-basis: 50%;
    max-width: 50%;
  }
}


/**/
/* product card */
/**/
.product-card {
  position: relative;
  display: block;
  margin-top: 0.75rem;
  padding: 0.5rem 0.75rem 0.75rem;
  border: 1px solid #E4E4E4;
  border-radius: 0.25rem;
  text-align: left;
  transition-duration: 0.25s;
  transition-property: box-shadow;
}

.product-card:hover {
  box-shadow: 0.25rem 0.25rem 0 0 rgba(77, 77, 78, 0.2);
}

.product-card__head {
  margin: 0 -0.375rem;
}

.product-card__pic {
  position: relative;
  padding-top: 100%;
}

.product-card__pic img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}

.product-card__new {
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 1px 0.125rem 0 0.375rem;
  background-color: #FF1111;
  font-size: 0.6875rem;
  line-height: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #FFF;
}

.product-card__new::after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  height: 1px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 4px solid #FF1111;
  box-sizing: content-box;
}

.product-card__like {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 0.75rem;
  color: #BFC1C6;
}

.product-card__hr {
  margin: 0.5rem 0;
  border: none;
  border-top: 1px solid #E5E5E5;
}

.product-card__summary {
  margin-top: 0.125rem;
  line-height: 1.375;
}

.product-card__stock {
  position: relative;
  margin-top: 0.25rem;
  padding-left: 0.625rem;
  font-size: 0.6875rem;
  font-weight: 700;
  color: #31B816;
}

.product-card__stock::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: #31B816;
  transform: translateY(-50%);
}

.product-card__stock_out {
  color: #BFC1C6;
}

.product-card__stock_out::before {
  background-color: #BFC1C6;
}

.product-card__price {
  margin-top: 2rem;
}

.product-card__price del {
  margin-left: 0.25rem;
  font-size: 0.75rem;
  color: #6C707D;
}

@media screen and (max-width: 767px) { /* mobile */
  .product-card:hover {
    box-shadow: none;
  }
  .product-card__price {
    margin-top: 1rem;
  }
  .product-card__summary {
    font-size: 0.75rem;
  }
}


/**/
/* product line */
/**/
.product-line {
  position: relative;
  display: flex;
  margin-top: 0.75rem;
  padding: 1rem;
  border: 1px solid #E4E4E4;
  border-bottom: 3px solid $color_primary_dark;
  border-radius: 0.25rem;
  transition-duration: 0.25s;
  transition-property: box-shadow;
}


.product-line:hover {
  box-shadow: 0.25rem 0.25rem 0 0 rgba(77, 77, 78, 0.2);
}

.product-line__head {
  margin-right: 1rem;
}

.product-line__pic {
  position: relative;
  display: block;
  width: 11.25rem;
  height: 11.25rem;
}

.product-line__pic img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.product-line__badge {
  position: absolute;
  top: 0;
  left: -1rem;
  margin-left: -1px;
}

.product-line__meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: -0.75rem;
  font-size: 0.6875rem;
}

.product-line__meta div {
  margin-top: 0.25rem;
  margin-right: 0.75rem;
  white-space: nowrap;
}

.product-line__stock {
  position: relative;
  padding-left: 0.625rem;
  font-weight: 700;
  color: #278f12;

  @media (max-width: 768px) {
    clear: both;
    top: -10px;
  }
}

.core-price {
  @media (max-width: 768px) {
    margin-left: 20px !important;
  }
}


/*
.product-line__stock::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: #31B816;
  transform: translateY(-50%);
}
*/


.product-line__stock_out {
  color: #BFC1C6;
}

.product-line__stock_out::before {
  background-color: #BFC1C6;
}

.product-line__summary {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #ECEDF0;

  &.fit {
    display: flex;
    clear: both;

    &.fits {
      border-top: 4px solid #1f5632;
      border-bottom: 4px solid #1f5632;
    }

    &.fits-addl-info-req {
      border-top: 4px solid #0FFF50;
      border-bottom: 4px solid #0FFF50;
    }

    &.maybe-fits {
      border-top: 4px solid #f58f22;
      border-bottom: 4px solid #f58f22;
    }

    &.no-fits {
      border-top: 4px solid #bc1f32;
      border-bottom: 4px solid #bc1f32;
    }

    padding-bottom: 9px;

    .img-wrap {
      display: block;
      width: auto;
    }

    .fits-msg {
      flex-grow: 1;
      margin-left: 2rem;

      .msg {
        margin-top: 10px;
        text-transform: capitalize;
        font-size: 1.0rem;
        font-weight: 700;

        span {
          font-weight: 400;
          padding-left: .4rem;
        }

        .fits-addl-info-req & {
          font-size: 1.5rem;
        }

        @media (max-width: 768px) {
          margin-top: 5px;
        }

      }

      .vehicle {
        margin-top: 5px;
        font-size: .9rem;
        font-weight: 700;

        label {
          color: red;
        }

        a {
          padding-left: .5rem;
          color: darkblue;
        }

      }

      .add-vehicle, .add-vehicle-simple {
        text-decoration: underline;
        cursor: pointer;
      }

    }


  }

  &.maybe-fits {
    border-top: 4px solid #231f20;
    border-bottom: 4px solid #231f20;
  }


}

.trust-cont {
  min-height: 72px;
  height: auto;
  justify-content: center;
  border-top: 4px #fff solid;
  border-bottom: 5px $color_primary solid;
  background-color: $color_secondary;

  .footer & {
    border-bottom: 0;
    background-color: $color_primary;
  }

  @media (max-width: 768px) {
    height: 72px;
    .footer & {
      height: auto;
      padding-bottom: 10px;
    }
  }

  .trust {
    display: flex;
    justify-content: center;
    flex-basis: content;
    margin-bottom: 5px;

    @media (max-width: 1026px) {
      flex-shrink: 3;
      flex-grow: 1;
      flex-basis: min-content;
    }

    @media (max-width: 768px) {
      flex-shrink: unset;
      flex-grow: unset;
      flex-basis: unset;
    }

    .img-wrap {
      display: block;
      width: auto;

      @media (max-width: 768px) {
        .footer & {
          width: 66px;
          text-align: center;
        }
      }

      img {
        height: 2rem;
        margin-top: 1rem;
      }
    }

    .trust-msg {
      color: #fff;
      flex-grow: 1;
      margin-left: .5rem;

      .title {
        margin-top: 10px;
        text-transform: uppercase;
        font-size: .9rem;
        font-weight: 700;
      }

      .msg {
        font-size: .8rem;
        font-weight: normal;
      }
    }
  }
}


.product-line-grade {
  float: right;
  position: relative;
  top: -107px;
}

.virota-grade {
  &.mobile {
    display: none;
  }

  text-align: center;
}

.product-line__fitment {
  margin-top: 0.5rem;
  line-height: 1.375;
}

.product-line__foot {
  //min-width: 13rem;
  min-width: 11rem;
  margin-left: 1rem;
  text-align: right;
}

.product-line__wishlist {
  vertical-align: top;
  font-size: .65rem;
  color: #BFC1C6;
  transition-duration: 0.25s;
  transition-property: color;
  cursor: pointer;
  text-transform: uppercase;

}

.product-line__wishlist:hover {
  color: #c12026;
}

.product-line__wishlist_active,
.product-line__wishlist_active:hover {
  color: #c12026;
}

.product-line__price {
  margin-top: 1.75rem;
  font-size: 1.2rem;

  &.mobile {
    @media (max-width: 768px) {
      margin-top: .5rem;
      margin-left: 0rem;
      float: left;
      position: relative;
      top: 40px;
    }
  }
}

.product-qty-dropdown {
  width: 5rem;
  float: right;

  @media (max-width: 768px) {
    margin-top: -20px !important;
  }
}

.product-qty-dropdown-detail {
  width: 5rem;
}

.star_rating_list {
  margin-top: 5px;
  margin-right: 20px;

    @media (max-width: 768px) {
      margin-right: 0;
    }

}

.product-line__button {
  margin-top: 0.5rem;
  width: 9rem;
  cursor: pointer;
  display: none;
}

.product-line__button_out {
  background-color: #BFC1C6;
  pointer-events: none;
}

.product-line__shipping {
  margin-top: 0.375rem;
  text-transform: uppercase;
  color: #31B816;
}

.product-line__heading {
  display: inline-block;
  float: left;
  margin-right: .5rem;
}

.product-line__subheading {
  display: inline-block;
  float: left;
  font-size: 0.975rem;
  line-height: 1.2rem;

  a {
    text-decoration: underline;
  }
}

.product-line__cont {
  width: 80%;
}


.product-line__shipping {
  font-size: 1rem;
  font-size: .75rem;
  font-weight: 700;
  color: #278f12;
}


@media screen and (max-width: 1023px) { /* tablet */
  .product-line__pic {
    width: 8rem;
    height: 8rem;
  }
  .product-line__pic img {
    width: auto;
    height: auto;
  }
  .product-line__foot {
    min-width: 10rem;
  }

  .product-line__cont {
    width: auto;
    margin-bottom: 40px;
  }

}

@media screen and (max-width: 767px) { /* mobile */

  // this class is only used on the product detail page, to align the grade image on mobile
  .product-line-grade {
    float: right;
    position: initial;
    padding-top: 15px;
  }

  // show the grade next to the attributes listing in mobile, else, it shows under the product image on desktop
  .virota-grade {
    &.mobile {
      position: relative;
      //top: -40px;
      display: block;
      float: right;
    }

    &.desktop {
      display: none;
    }

    text-align: center;
  }

  .product-line__heading {
    float: none;
    display: block;

  }
  .product-line__subheading {
    float: none;
    display: block;
  }

  .product-line {
    display: block;
    padding: 0.75rem;
  }
  .product-line:hover {
    box-shadow: none;
  }
  .product-line__head {
    float: left;
    margin-right: 0.75rem;
  }
  .product-line__pic {
    width: 5.5rem;
    height: 5.5rem;
  }
  .product-line__badge {
    left: -0.75rem;
  }
  .product-line__subheading {
    margin: 0.25rem 0;
  }
  .product-line__meta div {
    //display: none;
    //margin: 0;
  }
  .product-line__meta .product-line__stock {
    display: block;
  }
  .product-line__price {
    margin-top: 0.5rem;
    margin-left: 6.25rem;
  }

  .product-line__foot {
    width: auto;
    min-width: 100%;
    margin: 0;
    text-align: center;
  }
  .product-line__wishlist {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
  }
  .product-line__button {
    width: 100%;
  }
}


/**/
/* products list */
/**/
.products-list {
  margin-bottom: 5.5rem;
}

.products-list__more {
  margin-top: 0.75rem;
  cursor: pointer;
}

@media screen and (max-width: 767px) { /* mobile */
  .products-list {
    margin-bottom: 3.5rem;
  }
}


/**/
/* recently viewed */
/**/
.recently-viewed {
  margin-bottom: 5.5rem;
}

.recently-viewed__hr {
  margin: -2.5rem 0 3rem;
  border: none;
  border-top: 1px solid #BFC1C6;
}

.recently-viewed__title {
  margin-bottom: 1.25rem;
  text-align: center;
}

.recently-viewed__counter {
  position: relative;
  display: none;
  vertical-align: top;
  margin-top: 0.5rem;
  padding-right: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.recently-viewed__counter::after {
  content: '';
  position: absolute;
  bottom: 50%;
  left: 100%;
  width: 2rem;
  border-top: 1px solid;
}

@media screen and (max-width: 767px) { /* mobile */
  .recently-viewed {
    margin-bottom: 3.5rem;
  }
  .recently-viewed__hr {
    margin: -1.5rem 0 2rem;
  }
  .recently-viewed__title {
    margin-bottom: 0.5rem;
  }
  .recently-viewed__row {
    position: relative;
    display: block;
    height: 287px;
    overflow: hidden;
    margin: 0 -1rem;
  }
  .recently-viewed__row::after {
    content: '';
    position: absolute;
    top: 0.75rem;
    right: 0;
    bottom: 0;
    width: 4rem;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
  }
  .recently-viewed__row .slick-list {
    padding: 0 0.625rem;
  }
  .recently-viewed__col {
    width: 9.5rem;
    max-width: none;
  }
  .recently-viewed__counter {
    display: inline-block;
  }
}


/**/
/* product gallery */
/**/
.product-gallery {
  position: relative;
  margin-right: 0.5rem;
  padding-top: 100%;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 1px #ECEDF0, 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
}

.product-gallery__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition-duration: 0.25s;
  transition-property: opacity;
}

.product-gallery__item img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 87.5%;
  max-height: 87.5%;
}

.product-gallery__item_active {
  pointer-events: auto;
  opacity: 1;
}

.product-gallery__zoom {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  pointer-events: none;
  color: #EB9810;
}

@media screen and (max-width: 767px) { /* mobile */
  .product-gallery {
    margin-right: 0;
  }
}


/**/
/* product thumbs */
/**/
.product-thumbs {
  margin-right: 0.5rem;
  margin-bottom: 5.5rem;
}

.product-thumbs__item {
  position: relative;
  margin-top: 0.75rem;
  padding-top: 100%;
  box-shadow: inset 0 0 0 1px #ECEDF0, 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition-duration: 0.25s;
  transition-property: box-shadow;
  -webkit-user-select: none;
}

.product-thumbs__item img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.product-thumbs__item:hover {
  box-shadow: inset 0 0 0 1px #234476, 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
}

.product-thumbs__item_active,
.product-thumbs__item_active:hover {
  box-shadow: inset 0 0 0 2px #234476, 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 1211px) { /* laptop */
  .product-thumbs__col {
    flex-basis: 20%;
    max-width: 20%;
  }
}

@media screen and (max-width: 1023px) { /* tablet */
  .product-thumbs__col {
    flex-basis: 25%;
    max-width: 25%;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .product-thumbs {
    margin-bottom: 1.25rem;
  }
  .product-thumbs__row {
    position: relative;
    display: block;
    margin: 0 -1rem;
  }
  .product-thumbs__row::after {
    content: '';
    position: absolute;
    top: 0.75rem;
    right: 0;
    bottom: 0;
    width: 4rem;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
  }
  .product-thumbs__row .slick-list {
    padding: 0 0.625rem;
  }
  .product-thumbs__col {
    width: 6rem;
    max-width: none;
  }
}


/**/
/* product summary */
/**/


.product-summary__new {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0.5rem;
  padding: 1px 0.125rem 0 0.375rem;
  background-color: #FF1111;
  font-size: 0.6875rem;
  line-height: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #FFF;
}

.product-summary__new::after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  height: 1px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 4px solid #FF1111;
  box-sizing: content-box;
}

.product-summary__meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: -1rem;
}

.product-summary__meta div {
  margin-top: 0.375rem;
  margin-right: 1rem;
  white-space: nowrap;
}

.product-summary__stock {
  position: relative;
  padding-left: 0.625rem;
  font-size: 0.6875rem;
  font-weight: 700;
  color: #31B816;
}

.product-summary__stock::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: #31B816;
  transform: translateY(-50%);
}

.product-summary__price {
  margin-top: 0.5rem;
}

.product-summary__fitment {
  margin-top: 1rem;
}

.product-summary__fitment span {
  font-weight: 500;
}

.product-summary__button {
  display: inline-block;
  width: 19rem;
  margin-top: 1rem;
  border-radius: 0.25rem;
  background-color: $color_primary;
  font-size: 0.875rem;
  line-height: 2.75rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: #FFF;
  transition-duration: 0.25s;
  transition-property: background-color;
  display: none;
}

.product-summary__button:hover {
  background-color: #c12026;
  color: #FFF;
}

.product-summary__wishlist {
  //float: right;
  //margin-top: -2em;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.65rem;
  color: #565966;

  margin-top: 1rem;
  margin-left: 1rem;
}

.product-summary__wishlist i {
  //vertical-align: top;
  //margin-right: 0.375rem;
  font-size: 0.65rem;
  line-height: inherit;
  color: #565966;
  transition-duration: 0.25s;
  transition-property: color;
}

.product-summary__wishlist:hover {
  color: #c12026;

  i {
    color: #c12026;
  }
}

.product-summary__wishlist_active i {
  color: #c12026;
}

.product-summary__wishlist_active i:hover {
  color: #c12026;
}

.product-summary__shipping {
  font-size: 1rem;
  font-weight: 700;
  color: #278f12;
}

.free_shipping {
  font-weight: 700;
  color: #278f12;
}

.line-item-alert {
  font-weight: 700;
  color: #b92c28;
}

.product-list-selector .product-cont .eligible-shipping-help {
  font-size: 15px;
}


.product-summary__benefits {
  float: right;
  width: 13.5rem;
  margin-top: -77px;
  margin-left: 1rem;
  padding: 1.25rem 1rem 0;
  border: 1px solid #ECEDF0;
  border-radius: 0.25em;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.05);
  line-height: 1.375;
}

.product-summary__benefits i {
  position: relative;
  float: left;
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: #234476;
}

.product-summary__benefits img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.product-summary__benefits dd {
  margin: 1px 0 1.25rem;
  font-size: 0.75rem;
}

@media screen and (max-width: 1211px) { /* laptop */
  .product-summary__button {
    width: 15rem;
  }
}

@media screen and (max-width: 1023px) { /* tablet */
  .product-summary__wishlist {
    float: none;
    margin: 1rem 0 0;
  }
  .product-summary__button {
    display: block;
  }
  .product-summary__benefits {
    float: none;
    width: auto;
    margin: 0.75rem 0 0;
    padding: 0;
    border: none;
    box-shadow: none;
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .product-summary__wishlist {
    margin: 0;
    margin-top: 1rem;
    margin-left: 1rem;
  }
  .product-summary__button {
    //width: 100%;
  }
  .product-qty-dropdown-detail {
    width: 5rem;
    float: right;
    clear: both;
  }

  .product-summary__shipping {
    text-align: center;
  }
  .product-summary__benefits {
    display: flex;
    margin-top: 1.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .product-summary__benefits i {
    width: 2rem;
    height: 2rem;
  }
  .product-summary__benefits dl {
    min-width: 50%;
  }
  .product-summary__benefits dd {
    font-size: 0.6875rem;
  }
}


/**/
/* product details */
/**/
.product-details {
  margin-bottom: 5.5rem;
}

.product-details p {
  line-height: 2;
}


.product-details li {
  margin-top: 0.25rem;
  line-height: 1.75;
}

.product-details ul li ul li {
  padding-left: 2rem;
}

.product-details h6 {
  overflow: hidden;
  margin-top: 2rem;
  padding-top: 2rem;
  margin-bottom: 0.75rem;
  border-top: 1px solid #ECEDF0;
}

.product-details h6:first-child {
  margin-top: 1rem;
  padding-top: 1rem;
}

.product-details li::before {
  content: '- ';
}

.product-details__more {
  display: none;
  text-decoration: underline;
  color: #234476;
}

@media screen and (max-width: 767px) { /* mobile */
  .product-details {
    margin-bottom: 3.5rem;
  }
  .product-details p {
    max-height: 8em;
    overflow: hidden;
  }
  .product-details__more {
    display: block;
  }
}


/**/
/* promotion */
/**/
.promotion {
  margin: -0.75rem 0 5.5rem;
}

.promotion__item {
  position: relative;
  display: block;
  margin-top: 0.75rem;
}

.promotion__item::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 1) 100%);
}

.promotion__cover {
  max-width: 100%;
}

.promotion__cont {
  position: absolute;
  z-index: 1;
  right: 1.5rem;
  bottom: 1.25rem;
  left: 1.5rem;
  color: #FFF;
}

.promotion__subheading {
  max-width: 22rem;
  margin-top: 0.5rem;
}

@media screen and (max-width: 1211px) { /* laptop */
  .promotion__cover {
    height: auto;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .promotion {
    margin-bottom: 3.5rem;
  }
  .promotion__cont {
    right: 0.75rem;
    bottom: 0.5rem;
    left: 0.75rem;
  }
  .promotion__cont .h2 {
    font-size: 1.5rem;
  }
  .promotion__cont .h3 {
    font-size: 0.875rem;
  }
  .promotion__subheading {
    display: none;
  }
}


/**/
/* categories */
/**/
.categories {
  margin-bottom: 5.5rem;
}

.categories__title {
  margin-bottom: 1.25rem;
  text-align: center;
}

@media screen and (max-width: 767px) { /* mobile */
  .categories {
    margin-bottom: 3.5rem;
  }
  .categories__title {
    margin-bottom: 0.5rem;
  }
}


/**/
/* top models */
/**/
.top-models {
  margin-bottom: 5.5rem;
  text-align: center;
}

.top-models__title {
  margin-bottom: 1.25rem;
}

@media screen and (max-width: 767px) { /* mobile */
  .top-models {
    margin-bottom: 3.5rem;
  }
  .top-models__title {
    margin-bottom: 0.5rem;
  }
  .top-models__row {
    position: relative;
    display: block;
    margin: 0 -1rem;
  }
  .top-models__row::after {
    content: '';
    position: absolute;
    top: 0.75rem;
    right: 0;
    bottom: 0;
    width: 4rem;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
  }
  .top-models__row .slick-list {
    padding: 0 0.625rem;
  }
  .top-models__col {
    width: 6.375rem;
    max-width: none;
  }
}


/**/
/* recent posts */
/**/
.recent-posts {
  margin-bottom: 5.5rem;
}

.recent-posts__title {
  margin-bottom: 1.25rem;
  text-align: center;
}

.recent-posts__item {
  position: relative;
  display: block;
  overflow: hidden;
  margin-top: 0.75rem;
}

.recent-posts__item::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
}

.recent-posts__pic {
  max-width: 100%;
}

.recent-posts__cont {
  position: absolute;
  z-index: 1;
  right: 1.5rem;
  bottom: 0;
  left: 1.5rem;
  color: #FFF;
  transition-duration: 0.25s;
  transition-property: bottom;
}

.recent-posts__date {
  margin-bottom: 0.25rem;
}

.recent-posts__heading {
  max-width: 15rem;
}

.recent-posts__more {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #EB9810;
  opacity: 0;
  transition-duration: 0.25s;
  transition-property: opacity;
}

.recent-posts__more i {
  display: inline-block;
  vertical-align: top;
  margin-left: 0.375rem;
  font-size: inherit;
  line-height: inherit;
}

.recent-posts__item:hover .recent-posts__cont {
  bottom: 1rem;
}

.recent-posts__item:hover .recent-posts__more {
  opacity: 1;
}

@media screen and (max-width: 1211px) { /* laptop */
  .recent-posts__item::after {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 1) 100%);
  }
  .recent-posts__pic {
    height: auto;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .recent-posts {
    margin-bottom: 3.5rem;
  }
  .recent-posts__title {
    margin-bottom: 0.5rem;
  }
  .recent-posts__row {
    position: relative;
    display: block;
    margin: 0 -1rem;
  }
  .recent-posts__row::after {
    content: '';
    position: absolute;
    top: 0.75rem;
    right: 0;
    bottom: 0;
    width: 4rem;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
  }
  .recent-posts__row .slick-list {
    padding: 0 0.625rem;
  }
  .recent-posts__col {
    width: 12.75rem;
    max-width: none;
  }
  .recent-posts__cont {
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .recent-posts__date {
    font-size: 0.75rem;
  }
  .recent-posts__more {
    display: none;
  }
  .recent-posts__item:hover .recent-posts__cont {
    bottom: 0.75rem;
  }
}


/**/
/* brand card */
/**/
.brand-card {
  position: relative;
  display: block;
  margin-top: 0.75rem;
  padding-top: 100%;
  border-radius: 0.25rem;
  transition-duration: 0.25s;
  transition-property: box-shadow;
}

.brand-card img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 87.5%;
  max-height: 62.5%;
  margin: auto;
}

.brand-card::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #E4E4E4;
  border-radius: 0.25rem;
}

/*
.brand-card:hover {
  box-shadow: 0.25rem 0.25rem 0 0 rgba(77, 77, 78, 0.2);
}
*/
@media screen and (max-width: 767px) { /* mobile */
  .brand-card:hover {
    box-shadow: none;
  }
}


/**/
/* brands */
/**/
.brands {
  margin-bottom: 5rem;
}

.brands__title {
  margin-bottom: 1.25rem;
  text-align: center;
}

.brands__sort {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0 1.25rem;
  padding: 0.75rem 0;
  border-top: 1px solid #BFC1C6;
  border-bottom: 1px solid #BFC1C6;
  font-size: 0.75rem;
  font-weight: 500;

  .letter {
    padding: .6rem;
    font-size: 1rem;
  }

  span {
    margin-top: 10px;
    font-size: 15px;
  }
}

.brands__sort a {
  padding: 0 0.25rem;
  color: #717275;
  transition-duration: 0.25s;
  transition-property: color;
  text-transform: uppercase;
  cursor: pointer;
}

.brands__sort a:hover {
  color: #234476;
}

.brands__sort .active {
  position: relative;
  font-size: 1.125rem;
  line-height: 1.3;
  color: #234476;
}

.brands__sort .active::after {
  content: '';
  position: absolute;
  top: 100%;
  right: -0.25rem;
  left: -0.25rem;
  margin-top: 0.75rem;
  border-top: 2px solid;
}

@media screen and (max-width: 1023px) { /* tablet */
  .brands__sort span {
    display: none;
  }
  /*}

  @media screen and (max-width: 948px) { !* mobile *!*/
  .brands__sort {
    flex-wrap: wrap;
    padding: 0.5rem 0;
  }
  .brands__sort a {
    flex-basis: 7.692%;
    padding: 0.25rem 0;
    text-align: center;
  }
  .brands__sort .active::after {
    display: none;
  }
}


/**/
/* brand details */
/**/
.brand-details {
  margin-bottom: 3.5rem;
}

.brand-details__pic {
  position: relative;
  margin-right: 1rem;
  height: 15rem;
}

.brand-details__pic img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
}

.brand-details__pic::before {
  content: '';
  position: absolute;
  top: 0;
  right: -1rem;
  bottom: 0;
  width: 100vw;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.3);
}

.brand-details__cont {
  min-height: 15rem;
  margin-left: 1.75rem;
  padding-bottom: 1.75rem;
  border-bottom: 1px solid #E4E4E4;
}

.brand-details__number {
  margin-bottom: 0.75rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: #31B816;
}

@media screen and (max-width: 1023px) { /* tablet */
  .brand-details__cont {
    margin-left: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .brand-details__cont {
    margin-left: 0;
  }
}


/**/
/* shop brands */
/**/
.shop-brands {
  //margin-bottom: 3.75rem;
}

.shop-brands__subtitle {
  margin: 0.25rem 0;
}

@media screen and (max-width: 767px) { /* mobile */
  .shop-brands {
    //margin-bottom: 2.5rem;
  }
  .shop-brands__row {
    position: relative;
    display: block;
    margin: 0 -1rem;
  }
  .shop-brands__row::after {
    content: '';
    position: absolute;
    top: 0.75rem;
    right: 0;
    bottom: 0;
    width: 4rem;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
  }
  .shop-brands__row .slick-list {
    padding: 0 0.625rem;
  }
  .shop-brands__col {
    width: 6.375rem;
    max-width: none;
  }
}


/**/
/* featured brands */
/**/
.featured-brands {
  margin-bottom: 5.5rem;
}

.featured-brands__title {
  margin-bottom: 1.25rem;
  text-align: center;
}

@media screen and (max-width: 767px) { /* mobile */
  .featured-brands {
    margin-bottom: 3.5rem;
  }
  .featured-brands__title {
    margin-bottom: 0.5rem;
  }
  .featured-brands__row {
    position: relative;
    display: block;
    margin: 0 -1rem;
  }
  .featured-brands__row::after {
    content: '';
    position: absolute;
    top: 0.75rem;
    right: 0;
    bottom: 0;
    width: 4rem;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
  }
  .featured-brands__row .slick-list {
    padding: 0 0.625rem;
  }
  .featured-brands__col {
    width: 9.5rem;
    max-width: none;
  }
}


/**/
/* cart table */
/**/

.custom-select select, .custom-select .sb, .custom-select .pp {
  display: none; /*hide original SELECT element: */
}

.input-cart-qty-cont {
  @media screen and (max-width: 767px) {
    position: relative;
    top: -10px;
  }
}

.custom-select-qty {
  width: 90px;
  font-size: 17px;
  font-weight: normal;
  height: 45px;
  margin-left: -17px;
  margin-top: -3px;

  @media screen and (max-width: 767px) {
    margin-top: 0;
    margin-left: -10px;
  }
}


/* Style the arrow inside the select element: */
/*.select-selected:after {
  border: 1px solid #3273dc;
  border-top-color: rgb(50, 115, 220);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(50, 115, 220);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(50, 115, 220);
  border-left-color: rgb(50, 115, 220);
  border-right: 0;
  border-right-color: currentcolor;
  border-top: 0;
  border-top-color: currentcolor;
  content: " ";
  display: block;
  height: .5em;
  pointer-events: none;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: .5em;
  margin-top: -.375em;
  right: 1.125em;
  top: 50%;
  z-index: 4;
}

!* Point the arrow upwards when the select box is open (active): *!
.select-selected.select-arrow-active:after {
  border: 1px solid #3273dc;
  border-top-color: rgb(50, 115, 220);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(50, 115, 220);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(50, 115, 220);
  border-left-color: rgb(50, 115, 220);
  border-right: 0;
  border-right-color: currentcolor;
  border-top: 0;
  border-top-color: currentcolor;
  content: " ";
  display: block;
  height: .5em;
  pointer-events: none;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: .5em;
  margin-top: -.375em;
  right: 1.125em;
  top: 50%;
}*/

/* style the items (options), including the selected item: */


.select-items div, .select-selected {
  color: black;
  padding: 8px 16px;
  //border: 1px solid rgb(50, 115, 220);
  //border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  z-index: 102;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: white;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 102;
  border: 1px solid #dbdbdb;

  max-height: 300px;
  overflow: auto;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}


.cart-table {
  margin: 2rem 0;

  .remove-cont {
    @media screen and (max-width: 767px) {
      float: left;
      margin-left: 20px;
    }

    .remove-item {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.cart-table__row {
  width: 100%;
  margin-bottom: 0.75rem;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 1px #ECEDF0, 0 1px 2px rgba(0, 0, 0, 0.15);
}

.cart-table__th {
  width: 9rem;
  padding: 0 1.25rem;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 2.5rem;
  text-align: center;
  color: #989898;
}

.cart-table__th:first-child {
  width: auto;
  text-align: left;
}

.cart-table__td {
  padding: 1.25rem;
  border: none;
}

.cart-table__td_pic {
  width: 1%;
  padding-right: 0;
}

.cart-table__td_amount,
.cart-table__td_price {
  width: 9rem;
  padding: 0;
  text-align: center;
}

.cart-table__pic {
  position: relative;
  width: 6.875rem;
  height: 6.875rem;
}

.cart-table__pic img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.cart-table__badge {
  position: absolute;
  top: 0;
  left: -1.25rem;
}

.cart-table__fitment {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #ECEDF0;
}

.cart-table__input {
  width: 5.5rem;
  margin: 0 auto;
}

.cart-table__input i {
  position: absolute;
  top: 50%;
  right: 0;
  width: 2rem;
  font-size: 1.25rem;
  line-height: 2rem;
  margin-top: -1rem;
  text-align: center;
  cursor: pointer;
  color: #BFC1C6;
  transition-duration: 0.25s;
  transition-property: color;
}

.cart-table__input i:hover {
  color: #121212;
}

.cart-table__input i:first-child {
  right: auto;
  left: 0;
}

.cart-table__input input {
  text-align: center;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 500;
}

@media screen and (max-width: 1023px) { /* tablet */
  .cart-table__th {
    width: 8rem;
  }
  .cart-table__td_amount,
  .cart-table__td_price {
    width: 8rem;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .cart-table {
    margin-top: 1.25rem;
  }
  .cart-table__row,
  .cart-table__row tbody,
  .cart-table__row tr,
  .cart-table__td {
    display: block;
    width: auto;
  }
  .cart-table__row {
    padding: 0.75rem;
  }
  .cart-table__row_head {
    display: none;
  }
  .cart-table__td {
    padding: 0;
  }
  .cart-table__td_pic {
    float: left;
    width: auto;
    margin-right: 0.75rem;
    clear: both;
  }
  .cart-table__td_info {
    min-height: 5.5rem;
    overflow: hidden;
    margin-bottom: 1rem;
  }
  .cart-table__td_amount {
    float: left;
    width: auto;
    line-height: 2.75rem;
  }
  .cart-table__td_price {
    display: none;
    font-size: 1.25rem;
    text-align: right;
  }
  .cart-table__td_price-total {
    display: block;
    line-height: 2.75rem;
  }
  .cart-table__pic {
    width: 5.5rem;
    height: 5.5rem;
  }
  .cart-table__fitment {
    margin-top: 0.375rem;
    padding-top: 0.375rem;
  }
  .cart-table__input {
    width: 6.25rem;
  }
}


/**/
/* cart summary */
/**/

.copy-cart-url {
  margin-bottom: 1.25rem;
  padding: 0.75rem;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 1px #ECEDF0, 0 1px 2px rgba(0, 0, 0, 0.15);
  background-color: #F9F9F9;
  margin-left: 20px;
  cursor: pointer;
}

.cart-summary {
  width: 27rem;
  margin: 0 0 3.75rem auto;
}

.cart-summary__form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 2rem;
  background-color: #F3F3F3;

  &.saved-cart {
    background-color: $color_secondary;
    color: #fff;

    flex-wrap: wrap;

    // mobile
    @media screen and (max-width: 767px) {
      justify-content: center;
      padding-bottom: 40px;
      .cart-close {
        margin-top:20px;
      }
    }

    .saved-cart-label {
      font-weight: 700;
      font-size: 0.975rem;
    }

    .saved-id {
      color: #fff;
      font-size: 0.975rem;
      //text-decoration: underline;
    }

    .copy-cart-url {
      color: $color_primary;
      padding: 0.35rem;
    }

    .cart-close {
      background-color: $color_primary;
      font-size: 1rem;
    }
  }
}

.cart-summary__form .button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 0.625rem;
  line-height: 1.75rem;
}

.cart-summary__form .input-text {
  width: 13rem;
}

.cart-summary__form .input-text__input {
  padding: 0.5rem 0.75rem;
  box-shadow: none;
  font-size: 0.625rem;
  line-height: 0.75rem;
}

.cart-summary__method {
  margin-bottom: -0.875rem;
  padding: 1.25rem 2rem;
}

.cart-summary__method h5 {
  margin-bottom: 0.5rem;
}

.cart-summary__method input {
  position: absolute;
  left: -9999px;
}

.shipping-method-tr label {
  position: relative;
  display: block;
  margin: 0;
  padding: 0.875rem 0;
  border-bottom: 1px solid #EBEBEB;
  line-height: 1.125rem;
  cursor: pointer;
}

.shipping-method-tr label i {
  float: right;
  width: 1.125rem;
  height: 1.125rem;
  border: 2px solid #234476;
  border-radius: 50%;
  box-shadow: inset 0 0 0 3px #FFF;
  transition-duration: 0.25s;
  transition-property: background-color;
}

.shipping-method-tr:last-child {
  label {
    border-bottom: 0;
  }
}

.cart-summary__method input:checked + i {
  background-color: #234476;
}

.cart-summary__list {
  border-top: 1px solid #BFC1C6;
  padding: 1.25rem 2rem;
}

.cart-summary__list dt {
  float: left;
}

.cart-summary__list dd {
  margin-bottom: 0.75rem;
  text-align: right;
}

.cart-summary__list dd:last-child {
  margin-bottom: 0;
}

.cart-summary__button {
  display: block;
  margin: 1.25rem 2rem 0;
}

.cart-summary__button_paypal {
  background-color: #FFC438;
}

.cart-summart__button-issue_banner {
  margin: 10px;
}

@media screen and (max-width: 767px) { /* mobile */
  .cart-summary {
    width: auto;
  }
  .cart-summary__form {
    margin-right: -1rem;
    margin-left: -1rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .cart-summary__method {
    padding-right: 0;
    padding-left: 0;
  }
  .cart-summary__method label {
    font-size: 0.875rem;
  }
  .cart-summary__list {
    margin-right: -1rem;
    margin-left: -1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 0.875rem;
  }
  .cart-summary__button {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}


/**/
/* checkout headline */
/**/
.checkout-headline {
  margin-bottom: 2rem;
  text-align: center;
}

.checkout-headline__title {
  margin-top: 0.5rem;
}

.checkout-headline__thanks {
  font-weight: 400;
  color: #00BB34;
}

.checkout-headline__suptitle {
  margin-bottom: 0.5rem;
  font-weight: 300;
}

.checkout-headline__subtitle {
  margin-top: 0.5rem;
  font-weight: 500;
  color: #00BB34;
}


/**/
/* checkout steps */
/**/
.checkout-steps {
  position: relative;
  display: flex;
  margin-bottom: 2rem;
}

.checkout-steps__item {
  position: relative;
  flex-basis: 33.333%;
  min-width: 33.333%;
  font-size: 0.625rem;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  color: #D3DBE1;
}

.checkout-steps__item:nth-child(3) {
  text-align: center;
}

.checkout-steps__item:nth-child(4) {
  text-align: right;
}

.checkout-steps__num {
  margin-bottom: 0.5rem;
  color: #000;
}

.checkout-steps__dot {
  display: inline-block;
  vertical-align: top;
  width: 1.25rem;
  height: 1.25rem;
  margin-bottom: 0.625rem;
  border-radius: 50%;
  background-color: #D3DBE1;
}

.checkout-steps__progress {
  position: absolute;
  top: 1.25rem;
  right: 0;
  left: 0;
  height: 0.625rem;
  margin-top: 5px;
  border: 1px solid #D3DBE1;
  border-radius: 5px;
  background-color: #D3DBE1;
}

.checkout-steps__progress div {
  height: 0.5rem;
  border-radius: 0.25rem;
  background-color: #234476;
}

.checkout-steps__item_current {
  color: #234476;
}

.checkout-steps__item_current .checkout-steps__dot {
  background-color: #234476;
}

@media screen and (max-width: 767px) { /* mobile */
  .checkout-steps__name {
    max-width: 5.5rem;
  }
  .checkout-steps__item:nth-child(3) .checkout-steps__name {
    margin: 0 auto;
  }
  .checkout-steps__item:nth-child(4) .checkout-steps__name {
    margin-left: auto;
  }
}


/**/
/* checkout details */
/**/
.checkout-details {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.125rem 0;
  border-radius: 0.25rem;
  background-color: #F3F3F3;
  font-size: 0.8125rem;
}

.checkout-details__item {
  margin: 0 0 1rem;
}

.checkout-details__item em {
  display: block;
  font-style: normal;
  font-size: 1.125rem;
  font-weight: 300;
}

@media screen and (max-width: 1023px) { /* tablet */
  .checkout-details__item em {
    font-size: 1rem;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .checkout-details {
    flex-wrap: wrap;
  }
  .checkout-details__item {
    min-width: 50%;
  }
}


/**/
/* checkout form */
/**/
.checkout-form {
  margin-bottom: 3.75rem;
  padding: 1.5rem;
  border: 1px solid #ECEDF0;
  border-radius: 0.25rem;
}

.checkout-form__vr {
  width: 0.25rem;
  height: 337px;
  margin: 0 auto;
  background-color: #ECEDF0;
}

.checkout-form__hr {
  height: 0.25rem;
  margin: 1.25rem 0 0;
  border: none;
  background-color: #ECEDF0;
}

.checkout-form__col {
  margin-top: 1.25rem;
}

.checkout-form__label {
  font-size: 0.8125rem;
}

.checkout-form__label i {
  vertical-align: top;
  font-size: 0.75rem;
  line-height: 1;
  color: #BFC1C6;
}

.checkout-form__label span {
  color: #FF0000;
}

.checkout-form__radio {
  position: relative;
  display: block;
  padding-left: 1.5rem;
  font-size: 0.8125rem;
  line-height: 1rem;
  cursor: pointer;
  -webkit-user-select: none;
}

.checkout-form__radio i {
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  border: 2px solid #234476;
  border-radius: 50%;
  background-color: #FFF;
  box-shadow: inset 0 0 0 3px #FFF;
  transition-duration: 0.25s;
  transition-property: background-color;
}

.checkout-form__radio a {
  text-decoration: underline;
  color: #0A5D9B;
}

.checkout-form__radio a:hover {
  text-decoration: none;
}

.checkout-form__radio em {
  font-style: normal;
  color: #FF0000;
}

.checkout-form__radio img {
  vertical-align: top;
  margin-left: 0.25rem;
}

.checkout-form__radio input {
  position: absolute;
  left: -9999px;
}

.checkout-form__radio input:checked + i {
  background-color: #234476;
}

.checkout-form__radio input:checked ~ span {
  color: #234476;
}

.checkout-form__radio + .checkout-form__radio {
  margin-top: 1rem;
}

.checkout-form__radio_check i {
  border-radius: 0.125rem;
}

.checkout-form__cart {
  width: auto;
  margin: 0;
  padding: 0;
}

.checkout-form__cart dd {
  font-family: Oswald;
  font-weight: 500;
}

.checkout-form__cart .grand {
  float: none;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid #BFC1C6;
  text-align: right;
}

.checkout-form__cart .cart-summary__list {
  margin-top: 1.25rem;
  margin-right: 0;
  margin-left: 0;
  padding: 0;
  border-top: none;
}

.checkout-form__button {
  display: block;
  margin-top: 1.25rem;
  width: 100%;

  a {
    color: white;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .checkout-form {
    padding: 1rem;
  }
  .checkout-form__vr {
    width: auto;
    height: 0.25rem;
    margin: 1.25rem 0;
  }
}


/**/
/* checkout address */
/**/
.checkout-address {
  margin: 3.75rem 0;
}

.checkout-address p {
  line-height: 1.75;
}

.checkout-address__title {
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
}


/**/
/* my nav */
/**/
.my-nav {
  padding: 1.25rem 0;
  margin-right: 1.25rem;
  margin-bottom: 3.75rem;
  border-right: 3px solid #ECEDF0;
}

.my-nav__link {
  display: block;
  line-height: 3.5;
  transition-duration: 0.25s;
  transition-property: color;
}

.my-nav__link:hover {
  color: #234476;
}

.my-nav__link_active {
  margin-right: -3px;
  border-right: 3px solid #234476;
  font-size: 1.25rem;
  line-height: 2.5;
  font-weight: 500;
  pointer-events: none;
}

.my-nav__link_logout {
  color: #848484;
}

@media screen and (max-width: 767px) { /* mobile */
  .my-nav {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0 0 2rem;
    border-right: none;
    border-bottom: 3px solid #ECEDF0;
    font-size: 0.8125rem;
  }
  .my-nav__link_active {
    margin-bottom: -3px;
    border-right: none;
    border-bottom: 3px solid #234476;
    font-size: inherit;
    line-height: 3.5;
  }
}


/**/
/* my order */
/**/
.my-order {
  margin-bottom: 3.75rem;
}

.my-order__head {
  padding: 1rem 2rem;
  background-color: #234476;
  color: #FFF;
}

.my-order__head a {
  float: right;
  margin-left: 2rem;
  text-decoration: underline;
}

.my-order__head a:hover {
  text-decoration: none;
}

.my-order__cont {
  padding: 2rem;
  border: 1px solid #C8C8C8;
  border-top: none;
}

.my-order__table th {
  padding: 0 1rem 0.75rem 0;
  border-bottom: 1px solid #A8A9AA;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  color: #838383;
}

.my-order__table th:last-child {
  padding-right: 0;
  text-align: right;
}

.my-order__table th:first-child {
  text-align: left;
}

.my-order__table td {
  border-bottom: 1px solid #A8A9AA;
  padding: 1rem 1rem 1rem 0;
  font-size: 0.75rem;
  text-align: center;
}

.my-order__table td:last-child {
  padding-right: 0;
  text-align: right;
}

.my-order__table td:first-child {
  width: 1%;
}

.my-order__table td:nth-child(2) {
  text-align: left;
}

.my-order__pic {
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #FFF;
}

.my-order__pic img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.my-order__heading {
  font-size: 0.8125rem;
  color: #434343;
}

.my-order__subheading {
  margin-top: 0.25rem;
  font-size: 0.6875rem;
}

.my-order__total {
  margin-top: 1rem;
  font-weight: 500;
}

.my-order__total em {
  float: right;
  font-style: normal;
}

.my-order__total span {
  display: block;
  font-size: 0.8125rem;
  font-weight: 300;
}

.my-order__address {
  margin-top: 2rem;
  font-weight: 500;
}

.my-order__address span {
  display: block;
  font-size: 0.75rem;
  font-weight: 300;
}

@media screen and (max-width: 1023px) { /* tablet */
  .my-order__head {
    padding: 0.75rem 1rem;
  }
  .my-order__cont {
    padding: 1rem;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .my-order {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .my-order__cont {
    border: none;
  }
  .my-order__table th {
    font-size: 0.75rem;
  }
  .my-order__pic {
    width: 2.5rem;
    height: 2.5rem;
  }
}


/**/
/* my orders */
/**/
.my-orders {
  margin-bottom: 3.75rem;
  padding: 1.5rem;
  border: 1px solid #C8C8C8;
  border-radius: 0.25rem;
}

.my-orders__table {
  width: 100%;
}

.my-orders__table a {
  color: #234476;
}

.my-orders__table a:hover {
  text-decoration: underline;
}

.my-orders__table th {
  padding: 0 1rem 0.75rem;
  font-weight: 400;
}

.my-orders__table th:last-child {
  text-align: right;
}

.my-orders__table td {
  padding: 1.5rem 1rem;
  border-top: 1px solid #BFC1C6;
  background-color: #F5F5F5;
}

.my-orders__table td:last-child {
  text-align: right;
}

.my-orders__table tr:nth-child(even) td {
  background-color: transparent;
}

@media screen and (max-width: 1023px) { /* tablet */
  .my-orders__table {
    font-size: 0.8125rem;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .my-orders {
    padding: 0;
    border: none;
  }
  .my-orders__table {
    font-size: 0.75rem;
  }
  .my-orders__table th {
    padding: 0 0.5rem 0.5rem;
  }
  .my-orders__table td {
    padding: 1rem 0.5rem;
  }
}


/**/
/* my account */
/**/
.my-account {
  max-width: 33rem;
  margin-bottom: 3.75rem;
  padding: 2.5rem 3rem;
  border: 1px solid #E4E4E4;
  border-radius: 0.25rem;
  background-color: #F2F2F2;
}

.my-account__col {
  margin-bottom: 1.25rem;
}

.my-account__label {
  display: block;
  margin-bottom: 0.25rem;
}

.my-account__label span {
  color: #6C707D;
}

.my-account__button {
  padding: 0 3rem;
}

@media screen and (max-width: 767px) { /* mobile */
  .my-account {
    padding: 0;
    border: none;
    background-color: transparent;
  }
}


/**/
/* about */
/**/
.about {
  position: relative;
  margin: 5rem 0;
  padding: 2rem 0;
}

.about__vr {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 10px;
  overflow: hidden;
  border-radius: 5px;
  background-color: #DBE1EB;
}

.about__bar {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 40rem;
  border-radius: 5px;
  background-color: #234476;
}

.about__pic {
  max-width: 100%;
  margin-bottom: 1.75rem;
}

.about__heading {
  position: relative;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
}

.about__heading span {
  position: relative;
  padding-right: 1rem;
  background-color: #FFF;
}

.about__heading::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 1rem;
  left: 0;
  margin-top: -1px;
  border-top: 3px solid;
}

.about__text {
  line-height: 3;
}

.about-box {
  padding: 1rem 2rem 1rem 2rem;
}

.about-gray-box {
  margin-left: 5rem;
  background-color: #a5a5a5;
  color: #fff;

}

@media screen and (max-width: 1211px) { /* laptop */
  .about__pic {
    height: auto;
  }
}

@media screen and (max-width: 767px) { /* mobile */

  .about-gray-box {
    margin-left: 0;
  }
  .about {
    margin-bottom: 1.25rem;
    padding: 0;
  }
  .about__vr {
    display: none;
  }
  .about__item {
    margin-bottom: 3.75rem;
  }
}


/**/
/* process */
/**/
.process {
  margin-bottom: 5rem;
  border-top: 1.5rem solid #FFF;
  border-bottom: 1.5rem solid #FFF;
  background-color: #F6F6F6;
}

.process__pic {
  max-width: 100%;
  margin: -1.5rem 0;
}

.process__cont {
  padding: 1.25rem 0 1.25rem 1.25rem;
}

.process__heading {
  position: relative;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
}

.process__heading span {
  position: relative;
  padding-right: 1rem;
  background-color: #F6F6F6;
}

.process__heading::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 1rem;
  left: 0;
  margin-top: -1px;
  border-top: 3px solid;
}

.process__text {
  line-height: 2;
}

@media screen and (max-width: 1211px) { /* laptop */
  .process__pic {
    height: auto;
  }
}

@media screen and (max-width: 1023px) { /* tablet */
  .process__text {
    line-height: 1.75;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .process {
    border: none;
  }
  .process__cont {
    padding: 2rem 0;
  }
}


/**/
/* promises */
/**/
.promises {
  padding: 5rem 0;
  background-color: #F6F6F6;
}

.promises__title {
  margin-bottom: 2.5rem;
  text-align: center;
}

.promises__tabs {
  margin: 0 -1rem 1rem;
  font-size: 0;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.promises__tabs a {
  display: inline-block;
  vertical-align: top;
  margin: 0 1rem 1rem;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.3);
  transition-duration: 0.25s;
  transition-property: color;
}

.promises__tabs a:hover {
  color: rgba(0, 0, 0, 0.55);
}

.promises__tabs a.active {
  color: #234476 !important;
}

.promises__item {
  position: absolute;
  left: -9999px;
  pointer-events: auto;
  opacity: 0;
  transition-duration: 0.25s;
}

.promises__item p {
  margin: 0.75rem 0 0 1.25rem;
  line-height: 2;
}

.promises__item h5 {
  margin: 0 0 0 1.25rem;
}

.promises__item_active {
  position: static;
  pointer-events: auto;
  opacity: 1;
}

.promises__pic {
  max-width: 100%;
}

@media screen and (max-width: 1211px) { /* laptop */
  .promises__pic {
    height: auto;
  }
}

@media screen and (max-width: 1023px) { /* tablet */
  .promises__item .grid-row {
    align-items: flex-start;
  }
}

@media screen and (max-width: 767px) { /* tablet s */
  .promises__tabs {
    margin: 0 -0.5rem 1rem;
  }
  .promises__tabs a {
    margin: 0 0.5rem 0.5rem;
    font-size: 0.6875rem;
  }
  .promises__item p,
  .promises__item h5 {
    margin-left: 0;
  }
  .promises__pic {
    margin-bottom: 1rem;
  }
}


/**/
/* our brands */
/**/
.our-brands {
  margin-bottom: 5rem;
  text-align: center;
}

.our-brands__title {
  margin-bottom: 2rem;
}


/**/
/* callout */
/**/
.callout {
  position: relative;
  padding: 3.75rem 0 5rem;
  //background: #000 url('../img/callout/bg.jpg') 50% 50% no-repeat;
  background-size: cover;
  text-align: center;
  color: #FFF;
}

.callout__title {
  text-transform: uppercase;
}

.callout__suptitle {
  margin-bottom: 0.25rem;
  font-weight: 300;
}

.callout__button {
  width: 20rem;
  margin-top: 2rem;
}

@media screen and (max-width: 767px) { /* mobile */
  .callout__button {
    width: 16rem;
  }
}


/**/
/* contact drop */
/**/
.contact-drop {
  position: relative;
  margin-top: 2rem;
  margin-bottom: 3.75rem;
  padding: 1.25rem 0;
}

.contact-drop::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 65%;
  //background: url(../img/contact/bg.jpg) 50% 50% no-repeat;
  //background-size: cover;
}

.contact-drop__form {
  position: relative;
  width: 100%;
  padding: 1.5rem;
  //border: 1px solid #E2E2E2;
  //border-radius: 0.25rem;
  background-color: #FFF;
}

.contact-drop__title {
  margin-bottom: 1.25rem;
}

.contact-drop__col {
  margin-bottom: 1rem;
}

.contact-drop__label {
  display: block;
  margin-bottom: 0.25rem;
  font-size: 1.0rem;
}

.contact-drop__button {
  padding: 0 2rem;
}

@media screen and (max-width: 767px) { /* mobile */
  .contact-drop {
    padding: 0;
  }
  .contact-drop::before {
    display: none;
  }
  .contact-drop__form {
    width: auto;
  }
}

.required:after {
  content: " *";
  color: red;
}


/**/
/* contact info */
/**/
.contact-info {
  margin-bottom: 5rem;
}

.contact-info__title {
  margin-bottom: 1.5rem;
}

.contact-info__list dt {
  float: left;
  width: 10rem;
  font-weight: 500;
}

.contact-info__list dd {
  margin-bottom: 1.5rem;
  overflow: hidden;
  color: #9FA2AA;
}

.contact-info__list dd:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) { /* mobile */
  .contact-info__title {
    margin-bottom: 1rem;
  }
  .contact-info__list dt {
    width: 8rem;
  }
  .contact-info__list dd {
    margin-bottom: 1rem;
  }
}


/**/
/* privacy */
/**/
.privacy {
  margin-bottom: 5rem;
}

.privacy p {
  position: relative;
  margin-top: 1rem;
  padding-left: 2rem;
  font-size: 1rem;
  line-height: 2;
  font-weight: 300;
}

.privacy p a {
  text-decoration: underline;
}

.privacy p a:hover {
  text-decoration: none;
}

.privacy h6 {
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.privacy hr {
  margin: 2rem 0;
  border: none;
  border-top: 2px solid #F6F6F6;
}

.privacy__num {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
}

@media screen and (max-width: 767px) { /* mobile */
  .privacy p {
    font-size: 0.875rem;
  }
}


/**/
/* page404 */
/**/
.page404 {
  margin: 5rem 0;
  text-align: center;
}

.page404__pic {
  margin-bottom: 2rem;
}

.page404__title {
  font-weight: 700;
  font-size: 3rem;
  text-transform: uppercase;
}

.page404__subtitle {
  font-size: 1.25rem;
}

.page404__button {
  margin: 2rem 0 0;
  padding: 0 4rem;
}

@media screen and (max-width: 767px) { /* mobile */
  .page404__pic {
    max-width: 100%;
    height: auto;
  }
  .page404__subtitle {
    font-size: 1rem;
  }
}


/**/
/* page404 */
/**/
.page410 {
  height: 100vh;
  text-align: center;
}

.page410__num {
  font-size: 11.25rem;
  line-height: 1;
  font-weight: 900;
}

.page410__title {
  font-weight: 700;
  text-transform: uppercase;
}

.page410__link {
  display: inline-block;
  margin-top: 2rem;
  font-size: 0.875rem;
  font-weight: 700;
  text-decoration: underline;
  color: #234476;
}

.page410__link:hover {
  color: #234476;
}


/*----------------------------------------------*/
/*                    FOOTER                    */
/*----------------------------------------------*/

/**/
/* popup */
/**/
.popup {
  position: fixed;
  z-index: 100001;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  padding: 1rem 0;
  pointer-events: none;
  opacity: 0;
  transition-duration: 0.375s;
  transition-property: opacity;
}

.popup_active {
  pointer-events: auto;
  opacity: 1;
}

.popup__over {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.popup__close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.875rem;
  line-height: 1;
  color: #FF1111;
  transform: translateY(-50%);
}

.popup__close i {
  display: inline-block;
  vertical-align: top;
  margin-right: 0.25rem;
  font-size: 0.875rem;
}

.popup__close:hover {
  text-decoration: underline;
}

.popup__filter {
  display: block;
  margin: 1.25rem 0 0;
}

@media screen and (max-width: 767px) { /* mobile */
  .popup {
    padding: 0;
  }
}


/**/
/* garage */
/**/
.garage {
  position: relative;
  max-width: 80.25rem;
  margin: 0 auto;
  padding: 2.5rem 3.75rem;
  background-color: #FFF;
}

.garage__close {
  top: 2.5rem;
  right: 3.75rem;
  margin-top: 30px;
  color: $color_primary;
  background-color: #fff;
}


.garage__title {
  font-weight: 300;
}

.garage__subtitle {
  margin: 0.25rem 0 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #A7A7A7;
  font-size: 0.8125rem;
  color: #9FA2AA;
}

.garage__grid .model-card {
  text-align: left;
}

.garage__grid .model-card:hover {
  color: #234476;
}

.garage__grid .model-card:hover .model-card__goto {
  text-decoration: underline;
}

@media screen and (max-width: 1211px) { /* laptop */
  .garage {
    max-width: 60rem;
  }
}

@media screen and (max-width: 1023px) { /* tablet */
  .garage {
    max-width: 44rem;
    padding: 2rem;
  }
  .garage__close {
    top: 2rem;
    right: 2rem;
    margin-top: 25px;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .garage {
    padding: 1rem;
  }
  .garage__close {
    top: 1rem;
    right: 1rem;
    margin-top: 20px;
  }
}


/**/
/* delivery */
/**/
.delivery {
  position: relative;
  max-width: 46.375rem;
  margin: 0 auto;
  padding: 2rem;
  background-color: #FFF;
}

.delivery__title {
  font-weight: 300;
}

.delivery__subtitle {
  font-size: 0.8125rem;
  color: #9FA2AA;
}

.delivery__cont {
  margin-top: 1rem;
  padding-top: 1.5rem;
  border-top: 1px solid #BFC1C6;
}

.delivery__cont p {
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: 300;
}

.delivery__cont img {
  max-width: 100%;
}

@media screen and (max-width: 1023px) { /* tablet */
  .delivery__cont img {
    height: auto;
  }
}


/**/
/* benefits */
/**/
.benefits {
  margin-bottom: 3.75rem;
  padding: 3.75rem 0;
  border-bottom: 1px solid #9FA2AA;
  text-align: center;
}

.benefits__icon {
  height: 52px;
  margin-bottom: 0.5rem;
  line-height: 52px;
}

.benefits__icon img {
  vertical-align: bottom;
}

.benefits__item {
  padding: 0 1.25rem;
}

.benefits__subheading {
  margin-top: 0.25rem;
}

@media screen and (max-width: 1023px) { /* tablet */
  .benefits {
    display: none;
  }
}


/**/
/* foot nav */
/**/
.foot-nav__title {
  margin-bottom: 0.5rem;
  letter-spacing: 0;
}

.foot-nav__item {
  font-size: 0.75rem;
  line-height: 1.875rem;
}

.foot-nav__link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1023px) { /* tablet */
  .foot-nav {
    display: none;
  }
}


/**/
/* follow us */
/**/
.follow-us {
  margin-bottom: 1.25rem;
}

.follow-us__logo {
  display: none;
}

.follow-us__title {
  margin-bottom: 0.5rem;
  letter-spacing: 0;
}

.follow-us__item {
  display: inline-block;
  vertical-align: top;
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 0.5rem;
  border: 1px solid;
  border-radius: 50%;
  font-size: 0.75rem;
  line-height: 1.75rem;
  text-align: center;
}

.follow-us__item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1023px) { /* tablet */
  .follow-us__logo {
    display: block;
    margin: 0 auto 1rem;
  }
  .follow-us__title {
    display: none;
  }
}


/**/
/* subscribe */
/**/
.subscribe {
  margin-bottom: 1.25rem;
}

.subscribe__title {
  margin-bottom: 0.5rem;
  letter-spacing: 0;
}

.subscribe__form {
  position: relative;
}

.subscribe__button {
  position: absolute;
  padding: 0 1.25rem;
  top: 2px;
  right: 2px;
}

@media screen and (max-width: 1023px) { /* tablet */
  .subscribe__form {
    max-width: 25rem;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) { /* mobile */
  .subscribe__button {
    top: 0;
    right: 0;
    padding: 0 1rem;
    font-size: 0.75rem;
    line-height: 2rem;
  }
}


/**/
/* methods */
/**/
.methods {
  font-size: 0;
}

.methods img {
  margin-right: 0.5rem;
}

.methods img:last-child {
  margin-right: 0;
}

@media screen and (max-width: 767px) { /* mobile */
  .methods img {
    width: auto;
    height: 1.5rem;
  }
}


/**/
/* copyrights */
/**/
.copyrights {
  margin-top: 3.75rem;
  padding: 1rem 0;
  border-top: 1px solid #9FA2AA;
  font-size: 0.75rem;
  text-align: right;
}

.copyrights__text {
  float: left;
}

.copyrights__devs a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1023px) { /* tablet */
  .copyrights {
    margin: 0 -1rem;
    margin-top: 2rem;
    border-top: none;
    background-color: #234476;
    color: #fff;
    text-align: center;
  }
  .copyrights__text {
    float: none;
    margin-bottom: 0.25rem;
  }
}

@media screen and (max-width: 767px) { /* tablet s */
  .copyrights {
    font-size: 0.6875rem;
  }
}


/**/
/* parts nav */
/**/
.parts-nav {
  padding: 1.5rem 0;
  background-color: #FFF;
  font-size: 0.75rem;
  line-height: 2rem;
  font-weight: 300;
}

.parts-nav strong {
  font-weight: 500;
}

.parts-nav a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1023px) { /* tablet */
  .parts-nav {
    display: none;
  }
}


/* additional css by parts square */


.updated-saved-address {
  color: #234476;
  font-size: .8em;
  padding-top: 5px;
  text-decoration: underline;
  display: inline-block;
}

.saved-address-cont {
  margin-top: .25rem;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: auto;
}

.my-orders-filters th {

  padding: 0 1rem 0.75rem;
  font-weight: 400;

}

label.error {
  font-weight: bold;
  color: red;
  padding: 2px 8px;
  margin-top: 2px;
}

.cart-widget .strikethough {
  text-decoration: line-through;
}

.total-shipping-savings {
  font-size: 14px;
  text-transform: uppercase;
  color: #099700;
  font-weight: bold;
}

.cart-widget .item-error, .cart-widget .item-error {
  width: 100%;
  padding: 0px;
  padding-top: 3px;
  color: white;
  background-color: #fc727a;
}

.cart-widget .item-error span {
  padding-left: 20px;
  font-size: 0.975rem;
}

.product-line__fitment {

  .fits-table {

  }

  .table td, .table th {
    border: 0;
    padding: .45rem;
    padding-left: 0;
    font-weight: normal;

  }

  .table th {
    border-bottom: 1px solid #dee2e6;
  }

  .table td {

  }
}

.product-line__meta {
  div.product-interchange {
    overflow-wrap: break-word;
    white-space: normal;
  }

  clear: both;

}


.prod-attr-cont {

  span, .feature-desc {
    font-size: 0.875rem;
    font-weight: 800;
  }

  ul {
    li {
      span {
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }
}

.additional-fitment {
  font-weight: bold;
}

.toggle-hide-by-default {
  margin-left: 30px;
  margin-top: 10px;
  text-decoration: underline;
  color: #333;
  cursor: pointer;
  display: block;
}

.facet-prompt-list .hide-by-default {
  display: none;
}

.facet-highlight {

  display: none;
  // create a yellow transparent overlay
  //background-color: rgba(255, 255, 0, 0.5);
  background-color: yellow;
  // position the overlay on top of the search bar
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

}

.facet-prompt-list {
  position: relative;
}

.facet-prompt-list .visible {
  display: block;
}

.item-box-a {
  flex: 1;
  display: flex;
}

#shipping-details {
  display: none;
  margin-top: 40px;
}

.payment-errors-cont {
  display: none;
}


@media screen and (max-width: 767px) { /* mobile */

  .my-orders-filters {
    th {
      display: block;
      clear: both;
    }
  }

  .cart-widget .item-error {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .cart-widget .item-error td {
    padding-left: 10px;

  }

  .cart-widget .item-error td span {
    padding-left: 0px;
    font-size: 0.8rem;
  }

  .prod-attr-cont {
    span {
      font-size: 0.75rem;
      font-weight: 400;
    }

    ul {
      li {
        span {
          font-size: 0.75rem;
          font-weight: 400;
        }
      }
    }
  }

  .toggle-hide-by-default {
    display: none;
  }

  .facet-prompt-list .hide-by-default {
    display: inline-block;
  }

  .heading_all {
    font-size: .9rem;
  }

}

.my-garage-item {
  position: relative;

  .my-garage-remove {
    color: $color_primary;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;

  }

  .current-vehicle-text {
    color: white;
    position: absolute;
    left: 00px;
    bottom: 70px;
    cursor: pointer;
    background-color: $color_primary;
    width: 100%;
    text-align: center;
  }
}


.vehicle-widget {
  .my-garage-remove {
    color: white;
    cursor: pointer;
    margin-left: 10px;
  }

  label {
    span {
      a {
        text-decoration: underline;
        color: white;
      }
    }
  }
}

/*
.footer {
  color: #ffffff;
  background-color: #444444;
  border-top-color: #c12026;
}

.footer a {
  color: #ffffff;
  text-decoration: none;
}

.footer a:hover {
  color: #949494;
}

.footer .footer__title {
  font-family: 'PT Sans', sans-serif, "google";
  font-weight: bold;
}*/

.social .social__link {
  color: $color_primary;
  font-size: 14px;
}

.social .social__link:hover {
  color: #949494;
}

.credit-cards {
  img {
    width: 300px;
    height: auto;
    float: right;
  }

}

.credit-cards {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .credit-cards {
    margin-bottom: 20px;

    img {
      clear: both;
      float: none;
      text-align: center;
      margin: auto;
      display: inline-block;
      margin-top: 10px;
    }
  }
}


.ssl #ssl__link {
  color: #949494;
}

.ssl #ssl__link:hover {
  color: #949494;
}

.elist .elist__input {
  color: #000000;
  background-color: #ffffff;
  border-color: #eeeeee;
  box-shadow: none;
}

.elist .elist__input:hover,
.elist .elist__input:focus {
  box-shadow: none;
  background-color: #ffffff;
}

.elist .elist__input:focus {
  /* Chrome, Safari */
  /* Firefox 18- */
  /* Firefox 19+ */
  /* IE */
}

.elist .elist__input:focus::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.5;
}

.elist .elist__input:focus:-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}

.elist .elist__input:focus::-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}

.elist .elist__input:focus:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}

.elist .elist__submit {
  font-family: 'PT Sans', sans-serif, "google";
  font-weight: bold;
  background-color: #c12026;
  color: #ffffff;
  border-color: #ab1c22;
  transition: 0.2s;
  border-radius: 0px;
}

.elist .elist__submit:hover,
.elist .elist__submit:focus {
  background-color: #ab1c22;
  color: #ffffff;
  border-color: #ab1c22;
}

.elist .elist__submit[disabled],
.elist .elist__submit.disabled {
  background-color: #000000;
  color: white;
  border-color: #000000;
}

.elist .elist__submit:hover,
.elist .elist__submit:focus {
  background-color: #ab1c22;
  color: #ffffff;
  border-color: #ab1c22;
}

.elist .elist__submit[disabled],
.elist .elist__submit.disabled {
  background-color: #000000;
  color: white;
  border-color: #000000;
}

.copyright {
  font-size: 12px;
  color: #ffffff;
}

.copyright a {
  color: inherit;
}

.copyright a:hover {
  color: #949494;
}

/*
.footer .collapse__title {
  display: block;
}

@media only screen and (max-width: 767px) {
  .footer .collapse__title {
    border-bottom: 1px solid #222222;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 768px) {
  .footer .collapse__title {
    cursor: text;
  }
}

@media only screen and (min-width: 992px) {
  .footer .collapse__title {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .footer .collapse__title {
    font-size: 16px;
  }
}

.footer .collapse__title.collapsed .collapse__icon {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

@media only screen and (max-width: 767px) {
  .footer .collapse__body {
    padding: 15px;
    border-bottom: 1px solid #222222;
  }
}

.footer .collapse__icon {
  float: right;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

@media only screen and (min-width: 768px) {
  .footer .collapse__icon {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .footer .collapse,
  .footer .collapsing {
    display: block;
    height: auto !important;
  }
}*/

.link-column__list {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .link-column__list {
    padding-top: 0;
  }
}

@media only screen and (min-width: 992px) {
  .link-column__list {
    padding: 0;
  }
}

.link-column__item {
  margin-bottom: 15px;
}

.link-column__item:last-child {
  margin-bottom: 0;
}

.link-column__address {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .elist {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .elist {
    margin-bottom: 50px;
  }
}

.elist .elist__title {
  margin-bottom: 20px;
}

.elist .elist__title h3 {
  margin-top: 0;
  margin-right: 5px;
  margin-bottom: 0;
}

.elist__input {
  height: 40px;
  margin: 0;
  border: 0;
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
}

.elist__input::-webkit-input-placeholder {
  opacity: 0.75;
}

.elist__input:-moz-placeholder {
  opacity: 0.75;
}

.elist__input::-moz-placeholder {
  opacity: 0.75;
}

.elist__input:-ms-input-placeholder {
  opacity: 0.75;
}

.elist__input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.elist__input:focus::-webkit-input-placeholder {
  opacity: 0.5;
}

.elist__input:focus:-moz-placeholder {
  opacity: 0.5;
}

.elist__input:focus::-moz-placeholder {
  opacity: 0.5;
}

.elist__input:focus:-ms-input-placeholder {
  opacity: 0.5;
}

.elist__submit {
  height: 40px;
  background-color: #fff;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.elist .icon {
  width: 13px;
  height: 100%;
}

/*

.footer {
  border-top-width: 5px;
  border-top-style: solid;
}

@media only screen and (max-width: 767px) {
  .footer .links-column {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  .footer .links-column:first-child {
    border-top-width: 1px;
    border-top-style: solid;
  }
}

.footer__top {
  padding-top: 10px;
  padding-bottom: 30px;
}

@media only screen and (min-width: 992px) {
  .footer__top {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) {
  .footer__top-title {
    cursor: default;
  }
}

.footer__top-title.collapsed .ionicon.ion-android-remove-circle {
  display: none;
}

.footer__top-title:not(.collapsed) .ionicon.ion-android-add-circle {
  display: none;
}

.footer__top .footer__title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .footer__top .footer__title {
    padding-top: 5px;
    padding-bottom: 5px;
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .footer__top .footer__title {
    margin-bottom: 15px;
  }
}

.footer__bottom {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-width: 1px;
  border-top-style: solid;
}
*/


.productnamecolorLARGE {
  font-weight: bold;
}

.faq {
  a {
    text-decoration: underline;
    color: #0A5D9B;
    line-height: 2.3rem;
    font-weight: normal;
  }
}

.show-inline {
  display: inline-block;
}

.show {
  display: block;
}


// dropdown container for PPC

.dropdown-select-cont {
  .vehicle-search__select {
    &.hide {
      display: none;
    }
  }
}

.vehicle-selector-ppc-landing {
  h3 {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
    //color: #1c2540;
    color: #337ab7;
    margin: 5px 5px 5px 5px;
    margin-top: -40px;
    margin-bottom: 30px;
  }


  .menu-down {
    font-size: 30px;
    font-weight: bold;
    color: #ac0002;
    z-index: 7;
    border: 0;
    height: 23px;
    text-shadow: -1px 0 #b58969, 0 1px #fba809, 1px 0 #fba809, 0 -1px #fba809;
    position: absolute;
    left: 0;
    top: 7px;
    left: -45px;
    transform: rotate(-90deg);
    font-size: 30px;
    display: none;
  }

  .menu-down.active {
    display: block;
  }

}

.ppc-shilling {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 3rem;


  div {
    width: auto;
    text-align: center;
    padding: 3px;

    color: green;

    a {
      color: green;
    }

    i {
      color: green;
      font-size: 18px;
    }
  }
}

.dropdown-select-cont {
  width: 100%;
  z-index: 10;

  //padding-top: 20px;


  .action {
    font-family: 'Oswald', sans-serif;
    font-size: 26px;
    font-weight: bold;
    //color: #ac0002;
    color: #fff;
    text-transform: uppercase;
    margin-right: 2em;

  }

  .vehicle-search__select {
    margin-right: 2em;
  }

  .vehicle-widget .start-shopping {
    //color: #1c1c1e;
    color: #eef31f;
    font-size: 30px;
  }


}

.alert {
  &.hide {
    display: none;
  }
}

.vehicle-selector-dropdown {
  .hide {
    display: none;
  }
}


.vehicle-search-ppc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: -2.5rem;
  //  margin-bottom: 3rem;
  padding: 1.5rem 0;
  background-color: $color_secondary;
  color: #35383d;
  width: 100%;


  .vehicle-search__select {
    position: relative;
    width: 8.5rem;
    margin-left: 1rem;
  }

  .vehicle-search__select i {
    position: absolute;
    top: 0;
    right: 0.5rem;
    font-size: 0.875rem;
    line-height: 2.5rem;
    pointer-events: none;
    color: #454545;
  }

  .vehicle-search__select select {
    width: 100%;
    //padding: 0.9rem 1rem;
    border: 1px solid black;
    border-radius: 0.25rem;
    background-color: #FFF;
    font-size: .9rem;
    //line-height: 1.5rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-weight: 600;
  }

  .vehicle-search-button-submit {
    cursor: pointer;
    width: auto;
    background-color: #ac0002;
    font-weight: bold;

    text-decoration: none;
    border: 1px solid #1e2227;
    padding: 0.65rem 1rem;
    margin-left: 1rem;
    border-radius: 0.25rem;

    .label {
      font-size: 17px;
      font-weight: bold;
      color: white;
      font-family: 'Oswald', sans-serif;
    }
  }

  .vehicle-search__button.disabled {
    background-color: #fff;
    color: black;
    cursor: auto;
  }

  .vehicle-search__button:hover {
    background-color: #2F5A9C;
  }

  .vehicle-search__button.disabled:hover {
    background-color: #efefef;
    cursor: auto;
  }
}

@media screen and (max-width: 1023px) { /* tablet */

  .vehicle-selector-ppc-landing {
    h3 {
      font-size: 1.5rem;
      margin-bottom: 5px;
      margin-top: -20px;
    }
  }

  .vehicle-search-ppc {

    flex-direction: column;

    .vehicle-search__select {
      width: 10.5rem;
      margin-left: 0.75rem;

    }

    .vehicle-search__button {
      margin-left: 0.75rem;
    }

  }

  .dropdown-select-cont {
    width: 100%;

    .action {
      margin-right: 0;
      font-size: 1.2rem;
    }

    .vehicle-search__select {
      margin-right: 0;
    }

    .vehicle-widget .start-shopping {
      font-size: 1.2rem;
    }

  }


  .vehicle-search-ppc {
    margin: -1.25rem 0 1.25rem;
    //padding: 1rem 0 1.25rem;
    padding: 1rem 0 .4rem;
  }
  .no-vehicle-padding {
    padding: 1rem 0 1.25rem;
  }


  .vehicle-search-ppc {
    .vehicle-search__select {
      width: 14.75rem;
      margin: .4rem 0.125rem;
    }

    .vehicle-search__select i {
      font-size: 0.75rem;
      line-height: 2rem;
    }

    .vehicle-search__select select {
      padding: 0.5rem 0.75rem;
      //font-size: 0.75rem;
      line-height: 1rem;

    }

    .vehicle-search-button-submit {
      margin-top: .5rem;
      width: 14.75rem;
      margin-left: 0;
    }

  }
}

@media screen and (max-width: 767px) { /* mobile */

  .vehicle-selector-ppc-landing {
    h3 {
      text-align: center;
      font-size: 1.0rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 5px;
      margin-top: -10px;
    }
  }

  .vehicle-search-ppc {
    flex-direction: column;
  }


  .dropdown-select-cont {
    width: 100%;

    .action {
      margin-right: 0;
      font-size: .9rem;
    }

    .vehicle-search__select {
      margin-right: 0;
    }

    .vehicle-widget .start-shopping {
      font-size: .9rem;
    }

  }


  .vehicle-search-ppc {
    margin: -1.25rem 0 1.25rem;
    //padding: 1rem 0 1.25rem;
    padding: 1rem 0 .4rem;
  }
  .no-vehicle-padding {
    padding: 1rem 0 1.25rem;
  }

  .vehicle-search-ppc {
    .vehicle-search__select {
      width: 14.75rem;
      margin: .4rem 0.125rem;
    }

    .vehicle-search__select i {
      font-size: 0.75rem;
      line-height: 2rem;
    }

    .vehicle-search__select select {
      padding: 0.5rem 0.75rem;
      //font-size: 0.75rem;
      line-height: 1rem;

    }

    .vehicle-search-button-submit {
      margin-top: .5rem;
      width: 14.75rem;
      margin-left: 0;
    }
  }
}


.go-to-top-from-top {
  cursor: pointer;

}

a.go-to-top-from-top {
  color: #234476 !important;
}


.autocomplete-suggestions {
  border: 1px solid #999;
  background: #FFF;
  overflow: auto;

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

}

.autocomplete-suggestion {
  padding: 7px 7px;
  //white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid #efefef;
  padding-right: 10px;
  cursor: pointer;
}

.autocomplete-selected {
  background: #F0F0F0;
}

.autocomplete-suggestions strong {
  font-weight: normal;
  color: #3399FF;
}

.autocomplete-group {
  padding: 2px 5px;
}

.autocomplete-group strong {
  display: block;
  border-bottom: 1px solid #000;
}


.fancybox-container {
  z-index: 100001 !important;
}

.close {
  cursor: pointer;

  &.remove-search {
    color: $color_primary;
    font-weight: 700;
    text-decoration: underline;
  }
}