#footer {
  margin-top:20px;
  width: 100%;
  //height: 382px;


  .bar-wrapper {
    background-color: $color_primary_lighter;
    height: 65px;

    .footer-bar {
      background: url("../images/footer-bar.png") center top;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      width: 1000px;
      height: 65px;
      margin: auto;
    }
  }

  .page-footer__top {

    margin-top: 30px;
    border-bottom: 1px solid #E4E4E4;
    height: 3rem;

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      margin-top: 15px;

      @media (max-width: 768px) {
        justify-content: space-evenly;
      }

      li {
        margin: 0 20px 0 0;
        padding: 0 20px 0 0;
        border-right: 1px solid #607480;
        font-weight: 700;
        text-transform: uppercase;
        color: $color_secondary;
        font-size: 13px;

        &:last-child {
          border-right: none;
        }

        @media (max-width: 768px) {
          margin: 0;
          font-size: 11px;

        }

        a {

          .fa {
            color: $color_primary;
            padding-right: 5px;

          }
        }

        &:after {
          border-right: 1px solid #607480;
        }
      }
    }
  }


  .footer-logo {
    width: 243px;
    margin: auto;
    margin-top: 20px;
  }

  .footer-cc {
    width: 281px;
    height:30px;
    margin: auto;
    margin-top: 20px;
  }

  .copy {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    background-color: #f0f1f3;
    color: #7A7373FF;
    text-align: center;

    text-transform: uppercase;
    p {
      font-size: 11px;
    }
  }

}