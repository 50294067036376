$font-family-primary: 'Open Sans', sans-serif;
$font-family-secondary: 'Open Sans', sans-serif;
$font-family-special: 'Kaushan Script', cursive;

.font-primary-1 {
    font-family: $font-family-primary;
    font-size: rem(30px);
    font-weight: 800; //extrabold
    &.-variant-1 {
        font-size: rem(60px);
    }
    &.-variant-2 {
        font-size: rem(90px);
    }
}
.font-primary-2 {
    font-family: $font-family-primary;
    font-size: rem(24px);
    font-weight: 400; //regular
    font-style: italic;
    &.-variant-1 {
        font-size: rem(18px);
        font-style: normal;
    }
}
.font-primary-3 {
    font-family: $font-family-primary;
    font-size: rem(20px);
    font-weight: 500; //medium
    font-style: normal;
}
.font-primary-4 {
    font-family: $font-family-primary;
    font-size: rem(14px);
    font-weight: 600; //semibold
    font-style: normal;
}

.font-secondary-1 {
    font-family: $font-family-secondary;
    font-size: rem(14px);
    font-weight: 500; //medium
    font-style: italic;
    text-transform: uppercase;
    &.-variant-1 {
        font-style: normal;
    }
    &.-variant-2 {
        font-size: rem(10px);
    }
}
.font-secondary-2 {
    font-family: $font-family-secondary;
    font-size: rem(12px);
    font-weight: 400; //regular
    &.-variant-1 {
        font-size: rem(16px);
    }
}
.font-secondary-3 {
    font-family: $font-family-secondary;
    font-weight: 300; //light
}

.font-special {
    font-family: $font-family-special;
    font-size: rem(40px);
    font-weight: 400;
}
