
#header {


  @include media-breakpoint-down('md') {
    //height: 100px;
  }


  #nav-desktop {
    //top: rem(59px);
    //padding-left: rem(150px);
    //background-color: $color_primary_darker;

    &.trans {
      transition: background-color 0.5s ease-in-out;
    }

    &:hover {
      //background-color: $color_primary_darker;
      //background-color: #986354;
    }

    #navbarNav {

      .main-category {
        padding: 10px;
        cursor: pointer;

        @include media-breakpoint-down('xl') {
          padding: 5px;
        }

        img {
          color: $color_primary_darker;
        }

        figure {
          position: relative;
          max-width: 100%;
          margin: 0;

          img {
            //max-width: var(--icon-size);
            //max-width: 10rem;
            //max-height: var(--icon-size);
            //max-height: 10rem;
            //transform: scale(1);
            background-color: $color_secondary;

            @include media-breakpoint-down('xl') {
              width: 3em;
            }

            &.hover {
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              object-fit: contain;
              opacity: 0;
              transition: opacity .2s;
            }
          }
        }


        // hover effects for category grid item
        &:hover {
          // display secondary rollover image when hover
          .hover {
            opacity: 1;
          }

          // show background glow
          > .dropdown-item {
            background-color: $color_primary;
          }


        }

      }

      /* #all-categories {
         position: relative;
         z-index:2;
         &:hover {
           &::after {
             position: absolute;
             background-color: $color_secondary;
             border-top-left-radius: 15px;
             border-top-right-radius: 15px;
             height: 40px;
             width: 100px;
             left:0;
             border: 0;
             z-index:1;
           }
         }
       }*/

      @include media-breakpoint-down('md') {
        display: none;
      }

      .nav > li.dropdown.open {
        position: static;
      }

      .nav > li.dropdown.open .dropdown-menu {
        //display:table; width: 200px; text-align: center; left:0; right:0;
        width: 100%;
        text-align: center;
        left: 0;
        right: 0;
      }

      .navbar-nav {
        @extend .font-secondary-1;

        .nav-item-garage {
          margin-left: auto;
          padding: rem(10px, 0, 10px, 20px);
          line-height: rem(14px);
        }

        .nav-item {
          display: flex;
          align-items: center;
          color: $color_primary;


          &.dropdown {
            &:hover {
              //background-color: $color_primary_darker;
              //background-color: #986354;
              color: $color_secondary;
            }

          }


          @include media-breakpoint-down('lg') {
            //width: 35%;
          }

          .nav-link {

            padding-top: rem(19px);
            padding-bottom: rem(19px);
            white-space: nowrap;
            color: $color_secondary;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;

            &:hover {
              color: $color_primary;

              .reveal {
                display: inline-block;
                -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
              }
            }

            .reveal {
              display: none;
              -webkit-animation: tracking-out-expand 0.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
              animation: tracking-out-expand 0.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            }
          }

          .dropdown-menu {
            background-color: $color_secondary;
            border-radius: 0;
            border: none;
            transform: translate(0px, 55px) !important;

            &.big-menu {
              //height: 300px;
              height: auto;
              position: fixed !important;
              //margin-top: 140px !important;
              width: 100%;
              z-index: 100001;
            }

            .menu-wrapper {
              background-color: $color_secondary;
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              height: 300px;
              width: 100%;
            }

            > li {
              // display: table-cell;
              position: relative;
            }

            &.dropdown-submenu {

              //position: absolute;
              //left: 100%;
              //top: -7px;
              top: -115px;
              left: 0;


              .back {
                text-decoration: underline;
                font-size: 0.775rem;
              }

              .category-name {
                font-weight: 800;
                font-size: 1rem;
                color: $color_neutral;
                text-transform: uppercase;

                &:hover {
                  background-color: transparent;
                }
              }

              .submenu-navbar {
                display: block;
                width: 100%;
                height: 30px;
                border-bottom: 1px solid $color_primary;

                :first-child {
                  float: left;
                }

                :last-child {
                  float: right;
                  display: flex;

                  img {
                    width: 40px;
                    margin-top: -10px;
                    margin-right:5px;
                  }

                }
              }

              .dropdown-item {
                margin-left: 10px;
              }

            }

            & > li:hover > .dropdown-submenu {
              display: block;
            }


            .dropdown-item {

              @extend .font-secondary-1;
              color: $color_neutral;
              text-transform: capitalize;

              &:hover,
              &:focus {
                background-color: $color_primary;
              }


              @include media-breakpoint-down('lg') {
                white-space: normal;
                padding-right: 0;
                padding: 0;
              }

              @include media-breakpoint-down('xl') {
                font-size: 0.775rem;
                padding: 0;
              }


            }
          }

          .link-brand {
            //border-left: 2px solid #ff0000;
            //border-right: 2px solid #ff0000;
            padding-left: rem(20px);
            padding-right: rem(20px);
            margin-left: rem(8px);
            margin-right: rem(8px);
            //-webkit-transform: skew(-25deg, 0);

            .nav-link {
              padding: 0;
              color: $color_secondary;
              //-webkit-transform: skew(25deg, 0);
            }
          }
        }
      }
    }
  }

  .modal-body {
    &.-less-padding {
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }

  .modalMyGarage {

    @include media-breakpoint-down('md') {
      position: fixed;
    }

    top: 59px;
    //background: $color_primary_darker;
    display: flex;
    z-index: 9;
    width: 100%;
    padding: 4px;
    justify-content: flex-end;


    .modalSearchKeywordCTA {
      position: relative;
      right: 15px;
      z-index: 9;
      text-transform: capitalize;
      //font-style: italic;
      text-decoration: none;

      &.btn-link {
        color: $color_neutral;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }


    .sticky & {
      .modalSearchKeywordCTA {
        &.btn-link {
          color: $white !important;
        }
      }
    }

    .solid-header & {
      .modalSearchKeywordCTA {

        &.btn-link {
          color: $color_primary;

        }
      }
    }


  }

  .modalSearchContainer {
    display: none;

    @include media-breakpoint-down('md') {
      display: flex;
    }

    .modalSearchKeywordCTA {
      position: relative;
      right: 15px;
      z-index: 9;
      text-transform: capitalize;
      font-style: italic;
      text-decoration: none;
    }

  }

  #modalSearchKeyword {

    .btn-close {
      background-color: white;
    }

    .modal-content {
      background-color: $color_primary;
    }

    color: #fff;

    .selectedVehicle {
      display: block;
      text-align: left;
      background: $color_primary_darker;
      border: none;
      border-radius: 4px;
      margin-bottom: 10px;
      flex: 1 1 100%;

      .title {
        color: $color_primary;
        font-weight: bold;
      }

      .vehicle {
        color: $color_neutral;

        .remove {
          margin-right: 5px;
        }
      }
    }
  }

  #modalMyGarage {

    .btn-close {
      background-color: white;
    }

    color: #fff;

    .modal-content {
      background-color: $color_primary;
    }

    .selectedVehicle {
      display: block;
      text-align: left;
      background: $color_primary_darker;
      border: none;
      border-radius: 4px;
      margin-bottom: 10px;
      flex: 1 1 100%;

      .title {
        color: $color_primary;
        font-weight: bold;
      }

      .vehicle {
        color: $color_neutral;

        .remove {
          margin-right: 5px;
        }
      }
    }
  }


  // override colors of the main menu options, when you scroll down and the sticky header appears.
  .header-navigation {



    &.sticky {
      .modalSearchKeywordCTA {
        &.btn-link {
          color: $white !important;
        }
      }



      .link-brand {
        .nav-link {
          padding: 0;
          color: $white !important;
        }
      }
      .dropdown-menu.big-menu {
        margin-top: 8px !important;
      }
    }



  }


  .sticky #nav-desktop #navbarNav .navbar-nav .nav-item .dropdown-menu.big-menu {  margin-top: 8px !important; }


}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes tracking-out-expand {
  100% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  0% {
    opacity: 1;
  }
}

@keyframes tracking-out-expand {
  100% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  0% {
    opacity: 1;
  }
}

#header .solid-header #nav-desktop #navbarNav .navbar-nav .nav-item .link-brand .nav-link {
  color: $color_secondary;
}

#header .solid-header .modalMyGarage .modalSearchKeywordCTA.btn-link {
  color: $color_secondary;
}