footer {
    background-color: $color_neutral;
    padding: rem(60);
    .title {
        @extend .font-primary-1;
        font-size: rem(18);
        line-height: 1.2em;
        color: $color_primary;
        margin: rem(5, 0);
    }
    .links {
        @extend .font-secondary-2;
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 14px;
        li {
            padding: 2px 0;
            a {
                color: $color_primary;
            }
        }
    }
    .company {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: $color_primary;
        &-information {
            @extend .font-secondary-2;
            font-size: rem(14);
            padding-top: rem(35);
            .title {
                font-size: rem(14);
                line-height: 1em;
            }
            .email {
                a {
                    color: $color_primary;
                }
            }
        }
    }
    .menu {
        display: flex;
        flex-direction: row;
        //justify-content: flex-end;
        justify-content: space-around;
        align-items: flex-start;
        padding-top: rem(10);
        &-item {
            padding: rem(0, 30);
        }
    }
}

@include media-breakpoint-down(xl) {
    footer {
        padding: rem(15);
        .title {

        }
        .links {

        }
        .company {

        }
        .menu {
            &-item {
                padding: rem(0, 0);
            }
        }
    }
}
@include media-breakpoint-only(md) {
    footer {
        .title {

        }
        .links {

        }
        .company {

        }
        .menu {
            justify-content: space-between;
            &-item {

            }
        }
    }
}
@include media-breakpoint-only(sm) {
    footer {
        .title {

        }
        .links {

        }
        .company {

        }
        .menu {
            //justify-content: flex-start;
            justify-content: space-around;
            &-item {

            }
        }
    }
}
@include media-breakpoint-only(xs) {
    footer {
        .title {

        }
        .links {

        }
        .company {

        }
        .menu {
            flex-wrap: wrap;
            &-item {
                flex: 0 0 100%;
                margin-bottom: rem(15);
            }
        }
    }
}