$color_primary: #6b2508;
$color_primary_lighter: #771001;
$color_primary_dark: #9e0b0f;
$color_primary_darker: #6B2508FF;
$color_secondary: #30424e;
$color_secondary_darker: #111111;
$color_secondary_dark: #141618;
$color_secondary_light: #575757;
$color_secondary_lighter: #7c7c7c;
$color_neutral: #ffffff;
$color_neutral_1: #f0f0f0;
$color_neutral_2:#a7a7a7;
$color_neutral_3:#dbdbdb;
$color-neutral_4:#efefef;

$overlay-dark  :rgba($color_secondary, .8);